import React from 'react';
import emailjs from 'emailjs-com';
import { NavLink } from 'react-router-dom';
import '../App.css';
//import Axios from 'axios';

export default function Home() {

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [mobile, setMobile] = React.useState(0);
    const [query, setQuery] = React.useState("");
    
    function refreshPage() {
    window.location.reload(false);
  }
    
    // const displayInfo = () => {
    //   console.log(name + email + mobile + query);
    // }
    
    const submit = (e) => {
      
        if (name === "") {
          alert("Please fill in all your concerns correctly !!");
        } else {
        emailjs.send("service_pu31x5w","template_kc50e3p",{
              name: name,
              email: email,
              mobile: mobile,
              message: query,
          },"user_0wg4k6RlcSwXwu1KLbuaR")
          .then((result) => {
              console.log(result.text);
              alert("Your query has been successfully submitted. Will see you soon!");
              refreshPage();  
          }, (error) => {
              console.log(error.text);
              alert("Your query has been failed. Please check back again!");
              refreshPage(); 
          });
      }
          
      };
    


    return (
        <div className="bodyContainer">
            <div className="gstSlider show-for-small-only">
                <div className="orbit" role="region" aria-label="Favorite Space Pictures" data-orbit data-options="animation:fade; pause_on_hover:false; animation_speed:500;  timer_speed: 4000; navigation_arrows:true; slide_number: false; swipe: true;  bullets:false;">
                <div className="orbit-wrapper">
                    <div className="orbit-controls">
                    <button className="orbit-previous"><span className="show-for-sr">Previous Slide</span>&#9664;&#xFE0E;</button>
                    <button className="orbit-next"><span className="show-for-sr">Next Slide</span>&#9654;&#xFE0E;</button>
                    </div>
                    <ul className="orbit-container">
                    <li className="is-active orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Mobile-Slider-1.jpg" alt="audit-inspection" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Mobile-Slider-2.jpg" alt="business_finger" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Mobile-Slider-3.jpg" alt="GST" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Mobile-Slider-4.jpg" alt="TDS" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    </ul>
                </div>
                {/* <nav className="orbit-bullets">
                    <button className="is-active" data-slide="0">
                    <span className="show-for-sr">First slide details.</span>
                    <span className="show-for-sr" data-slide-active-label>Current Slide</span>
                    </button>
                    <button data-slide="1"><span className="show-for-sr">Second slide details.</span></button>
                    <button data-slide="2"><span className="show-for-sr">Third slide details.</span></button>
                    <button data-slide="3"><span className="show-for-sr">Fourth slide details.</span></button>
                </nav> */}
                </div>
            </div>
            <div className="desktop hide-for-small-only">
                <div className="orbit" role="region" aria-label="Favorite Space Pictures" data-orbit data-options="animation:fade; pause_on_hover:false; animation_speed:500;  timer_speed: 4000; navigation_arrows:true; slide_number: false; swipe: true;  bullets:false;">
                <div className="orbit-wrapper">
                    <div className="orbit-controls">
                    <button className="orbit-previous"><span className="show-for-sr">Previous Slide</span>&#9664;&#xFE0E;</button>
                    <button className="orbit-next"><span className="show-for-sr">Next Slide</span>&#9654;&#xFE0E;</button>
                    </div>
                    <ul className="orbit-container">
                    <li className="is-active orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Slider-1.jpg" alt="audit-inspection" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Slider-2.jpg" alt="business_finger" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Mobile.jpg" alt="GST" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    <li className="orbit-slide">
                        <figure className="orbit-figure">
                        <img className="orbit-image" src="images/Slider-4.jpg" alt="TDS" />
                        {/* <figcaption className="orbit-caption"></figcaption> */}
                        </figure>
                    </li>
                    </ul>
                </div>
                {/* <nav className="orbit-bullets">
                    <button className="is-active" data-slide="0">
                    <span className="show-for-sr">First slide details.</span>
                    <span className="show-for-sr" data-slide-active-label>Current Slide</span>
                    </button>
                    <button data-slide="1"><span className="show-for-sr">Second slide details.</span></button>
                    <button data-slide="2"><span className="show-for-sr">Third slide details.</span></button>
                    <button data-slide="3"><span className="show-for-sr">Fourth slide details.</span></button>
                </nav> */}
                </div>
                </div>
                
                <div className="row  hide-for-small-only">
                    
                    <div className="FormConMobile marginTop10 marginBottom10 paddingRight30 marginRight10">
                    <form>
                    <span className="borderOrange70" style={{margin: "0 auto"}}></span>
                    <h3 className="middle query1Form">GET IN TOUCH</h3>
                    <span className="borderOrange70 spanGetTouchTxt"></span>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="name" className="text-right middle query1Form"><b>Name</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="text" id="name" placeholder="Please Enter your Good Name" required='required' onChange={(event)=>{ setName(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="email" className="text-right middle query1Form"><b>Email</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="email" id="email" placeholder="Please Enter your Email" required='required' onChange={(event)=>{ setEmail(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="mobile" className="text-right middle query1Form"><b>Mobile</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="tel" id="mobile" placeholder="Please Enter your Point Of Contact" required='required' onChange={(event)=>{ setMobile(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="query" className="text-right middle query1Form"><b>Description</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="text" id="query" placeholder="Please write your Concern..." required='required' onChange={(event)=>{ setQuery(event.target.value); }}/></div>
                    </div>
                    <button type="button" className ="button" id="save" onClick={submit}>&nbsp;SUBMIT&nbsp;</button>
                    </form>
                    </div>
                </div>

                <div className="row  show-for-small-only">
                    
                    <div className="FormMobile marginTop10">
                    <form>
                    <span className="borderOrange70" style={{margin: "0 auto"}}></span>
                    <h3 className="middle query1Form">GET IN TOUCH</h3>
                    <span className="borderOrange70 spanGetTouchTxt"></span>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="name" className="text-right middle query1Form"><b>Name</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="text" id="name" placeholder="Please Enter your Good Name" required='required' onChange={(event)=>{ setName(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="email" className="text-right middle query1Form"><b>Email</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="email" id="email" placeholder="Please Enter your Email" required='required' onChange={(event)=>{ setEmail(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="mobile" className="text-right middle query1Form"><b>Mobile</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="tel" id="mobile" placeholder="Please Enter your Point Of Contact" required='required' onChange={(event)=>{ setMobile(event.target.value); }}/></div>
                    </div>
                    <div className="grid-x grid-padding-x">
                    <div className="small-2 large-3 cell">
                        <label htmlFor="query" className="text-right middle query1Form"><b>Query</b>
                        </label>
                    </div>
                    <div className="small-10 large-9 cell">
                        <input type="text" id="query" placeholder="Please write your Concern..." required='required' onChange={(event)=>{ setQuery(event.target.value); }}/></div>
                    </div>
                    <button type="button" className ="button" id="save" onClick={submit}>&nbsp;SUBMIT&nbsp;</button>
                    </form>
                    </div>
                </div>
                 <div className="grid-x grid-padding-x borderTop2  hide-for-small-only">
                
                    <div className="card small-12 large-4 bshadow">
                    <div className="card-divider"  style={{display: "inline-block",background: "rgb(255 138 0)",color: "#fff"}}>
                        <h4>GST</h4>
                    </div>
                    <div className="inner">
                        <img src="images/gst_box.jpg" className="card-img-top" alt="gst_registration" />
                    </div>
                    <div className="card-section">
                        <p className="lineHeight1_2">GST Health Tax team is equipped with knowledge and expertise. We can help you to strategizes, follow and complete the tax compliance in time with accuracy and clarity.</p>
                        <div className="row">
                            <NavLink to="/GstService">
                                <button className="btn">Read More</button>
                            </NavLink>
                        </div>
                    </div>
                    </div>
                    <div className="card small-12 large-4 bshadow">
                    <div className="card-divider"  style={{display: "inline-block",background: "#14679e",color: "#fff"}}>
                    <h4>Start a Business</h4>
                    </div>
                    <div className="inner">
                    <img src="images/start_business_box.jpg" className="card-img-top" alt="startup_business" />
                    </div>
                    <div className="card-section">
                    <p>GST Health expert can help you to decide best startegy to setup Business with the registrations, licenses &amp; related process. Onfiling is here to execute your business idea.</p>
                    
                    <div className="row">
                        <NavLink to="/StartBusiness">
                            <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                </div>
                <div className="card small-12 large-4 bshadow">
                    <div className="card-divider" style={{display: "inline-block",background: "#3adb76",color: "#fff"}}>
                    <h4>Accounting &amp; Reporting</h4>
                    </div>
                    <div className="inner">
                    <img src="images/accounts_box.jpg" className="card-img-top" alt="reporting" />
                    </div>
                    <div className="card-section">
                    <p>GST Health Bookkeeping Services are focused at reducing the finance and accounting costs by helping them with their management, accounting and tax preparation needs.</p>
                   
                    <div className="row">
                        <NavLink to="/BookAccountingService">
                            <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                </div>

                </div>
            

                <div className="grid-x grid-padding-x borderTop2  hide-for-small-only">
                
                    <div className="card small-12 large-4 bshadow">
                    <div className="card-divider"  style={{display: "inline-block",background: "#4cc5f3",color: "#fff"}}>
                        <h4>Income Tax</h4>
                    </div>
                    <div className="inner">
                        <img src="images/tax_box.jpg" className="card-img-top" alt="income_tax" />
                    </div>
                    <div className="card-section">
                        <p className="lineHeight1_2">GST Health Tax team is equipped with knowledge and expertise. We can help you to strategizes, follow and complete the tax compliance in time with accuracy and clarity.</p>
                    
                    <div className="row">
                        <NavLink to="/ITService">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    <div className="card small-12 large-4 bshadow">
                    <div className="card-divider"  style={{display: "inline-block",background: "rgb(103, 37, 26)",color: "#fff"}}>
                        <h4>Digital Signature</h4>
                    </div>
                    <div className="inner">
                        <img src="images/digital_signature_box.jpg" className="card-img-top" alt="digital-signature" />
                    </div>
                    <div className="card-section">
                        <p>GST Health expert can help you to decide best startegy to setup Business with the registrations, licenses &amp; related process. Onfiling is here to execute your business idea.</p>
                    
                    <div className="row">
                        <NavLink to="/DigitalSignature">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    <div className="card small-12 large-4 bshadow">
                    <div className="card-divider" style={{display: "inline-block",background: "#03c3ba",color: "#fff"}}>
                        <h4>Trademark Registration</h4>
                    </div>
                    <div className="inner">
                        <img src="images/trademark_box.jpg" className="card-img-top" alt="trademark" />
                    </div>
                    <div className="card-section">
                        <p>Trademark on a “brand” or “logo” or “Tag Line” ensures there is no copy of your product or services by counterfeit or competitors.</p>
                   
                    <div className="row">
                        <NavLink to="/tradeReg">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>

                </div>

                <div className="row show-for-small-only">


                <ul className="accordion" data-accordion>
                
                <li className="accordion-item is-active" data-accordion-item>
                    <NavLink to="" className="accordion-title">GST</NavLink>
                    <div className="accordion-content" data-tab-content>
                    <div className="card small-12 large-4">
                    
                    <div className="inner">
                        <img src="images/gst_box.jpg" className="card-img-top" alt="trademark_registration" />
                    </div>
                    <div className="card-section">
                        <div id="wrapper">
                            <p className="">GST Health Tax team is equipped with knowledge and expertise. We can help you to strategizes, follow and complete the tax compliance in time with accuracy and clarity....</p>
                            <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                        
                        <div className="row">
                        <NavLink to="/GstService">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    </div>
                </li>
                
                <li className="accordion-item" data-accordion-item>
                    <NavLink to="" className="accordion-title">Trademark Registration</NavLink>
                    <div className="accordion-content" data-tab-content>
                    <div className="card small-12 large-4">
                   
                    <div className="inner">
                        <img src="images/trademark_box.jpg" className="card-img-top" alt="trademark_registration" />
                    </div>
                    <div className="card-section">
                        <div id="wrapper">
                            <p className="">Trademark on a “brand” or “logo” or “Tag Line” ensures there is no copy of your product or services by counterfeit or competitors...</p>
                            <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                        
                        <div className="row">
                        <NavLink to="/tradeReg">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    </div>
                </li>
                <li className="accordion-item" data-accordion-item>
                    <NavLink to="" className="accordion-title">Digital Signature</NavLink>
                    <div className="accordion-content" data-tab-content>
                    <div className="card small-12 large-4">
                   
                    <div className="inner">
                        <img src="images/digital_signature_box.jpg" className="card-img-top" alt="digital-signature" />
                    </div>
                    <div className="card-section">
                    <div id="wrapper">
                            <p className="">GST Health expert can help you to decide best startegy to setup Business with the registrations,licenses &amp; related process. Onfiling is here to execute your business idea... </p>
                            <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                        <div className="row">
                        <NavLink to="/DigitalSignature">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    </div>
                </li>
                <li className="accordion-item" data-accordion-item>
                    <NavLink to="" className="accordion-title">Income Tax</NavLink>
                    <div className="accordion-content" data-tab-content>
                    <div className="card small-12 large-4">
                    
                    <div className="inner">
                        <img src="images/tax_box.jpg" className="card-img-top" alt="income_tax" />
                    </div>
                    <div className="card-section">
                        <div id="wrapper">
                            <p className="">GST Health Tax team is equipped with knowledge and expertise. We can help you to strategizes, follow and complete the tax compliance in time with accuracy and clarity... </p>
                            <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                        <div className="row">
                        <NavLink to="/ITService">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                    </div>
                    </div>
                </li>
                <li className="accordion-item" data-accordion-item>
                    <NavLink to="" className="accordion-title">Accounting &amp; Reporting</NavLink>
                    <div className="accordion-content" data-tab-content>
                        <div className="card small-12 large-4">
                        
                        <div className="inner">
                        <img src="images/accounts_box.jpg" className="card-img-top" alt="reporting"/>
                        </div>
                        <div className="card-section">
                        
                        <div id="wrapper">
                        <p className="">GST Health Bookkeeping Services are focused at reducing the finance and accounting costs by helping them with their management, accounting and tax preparation needs.</p>
                        <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                        <div className="row">
                        <NavLink to="/BookAccountingService">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li className="accordion-item" data-accordion-item>
                    <NavLink to="" className="accordion-title">Start a Business</NavLink>
                    <div className="accordion-content" data-tab-content>
                    <div className="card small-12 large-4">
                   
                    <div className="inner">
                    <img src="images/start_business_box.jpg" className="card-img-top" alt="startup_business" />
                    </div>
                    <div className="card-section">
                    <div id="wrapper">
                        <p className="">GST Health expert can help you to decide best startegy to setup Business with the registrations, licenses &amp; related process. Onfiling is here to execute your business idea.</p>
                        <p className=''> <span className='line'>Click to read</span></p>
                        </div>
                    <div className="row">
                        <NavLink to="/StartBusiness">
                        <button className="btn">Read More</button>
                        </NavLink>
                    </div>
                    </div>
                </div>
                    </div>
                </li>
            </ul>
            </div>
        </div>
    )
}
