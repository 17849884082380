import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const BookAccountingService = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">Book keeping, Accounting &amp; Reporting</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                            Accounting is a process which records all your financial transactions. Accounting is an important concept for every business as they need to submit their financial records and transactions.
                            <br/>GST Health helps to keep your account sorted from the beginning and keeps the books updated. It will help in tax compliance and decision making of crucial financial matters at the same time when you are handling your business.
                            <br/>The services provided by GST Health are:

                        </p>
                        <span className="headingH2 label primary marginTop20">Bookkeeping</span>
                        <p className="marginTop10">
                            Bookkeeping refers to the recording of the company’s financial transactions on a daily basis. When accurate bookkeeping is done on regular basis then only companies are able to track information on its books to make key operating, investing and financing decisions
                        </p>
                        <span className="headingH2 label primary">GST Health provides the following services:</span>
                        <ul className="marginTop10">
                            <li>Day to day accounting.</li>
                            <li>Accounting software  for sale invoice with Eway Bill feature.</li>
                            <li>Reconciliations.</li>
                            <li>Accounts receivable and payable (debtors and creditors reconciliations).</li>
                            <li>Inventory management.</li>
                            <li>Purchase and expenses booking.</li>
                            <li>Maintain books of accounts and supporting documents at cloud server.</li>
                            <li>GST and TDS compliances and filings.</li>
                            <li>Preparation of Balance sheet &amp; Financial statements.</li>
                            <li>Yearly Reporting.</li>
                        </ul>
                        <span className="headingH2 label primary marginTop20">How does GST Health work?</span>
                        <span className="headingH3 display marginTop10">Accounting &amp; Reporting</span>
                        <p>
                            GST Health helps in drafting your financial statement and analysis which provides a true and fair picture of your financials and helps to understand, plan and implement business strategies.
                        </p>
                        <ul className="marginTop10">
                            <li>Record of financial transactions related to business is maintained.</li>
                            <li>Preparation of financial statement and balance sheet.</li>
                            <li>Provides concise summary of financial transactions over an accounting period.</li>
                            <li>Implementation and selection of Accounting software.</li>
                            <li>Analysis of Cash Flow &amp; Budget.</li>
                            <li>Financial Projections &amp; Forecasts.</li>
                            <li>Business valuation for startups.</li>
                        </ul>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default BookAccountingService
