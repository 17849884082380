import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const StartBusiness = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="startBiz-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">Sole Proprietorship</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">Partnership Firm Registration</a></li>
                    <li className="tabs-title"><a href="#panel3v" onClick={refreshPage} className="headingH3">Private Limited Company Registration</a></li>
                    <li className="tabs-title"><a href="#panel4v" onClick={refreshPage} className="headingH3">Limited Liability Partnership Registration</a></li>
                    <li className="tabs-title"><a href="#panel5v" onClick={refreshPage} className="headingH3">One Person Company Registration</a></li>
                    <li className="tabs-title"><a href="#panel6v" onClick={refreshPage} className="headingH3">Public Limited Registration</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="startBiz-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">Sole Proprietorship</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">What is a Sole Proprietorship?</span>
                      <p className="marginTop10">A Sole Proprietorship or Proprietorship is a type of enterprise which has no legal distinction between the owner and the business entity. It is owned and run by one person. A sole trader does not necessarily work 'alone'—it is possible for the sole trader to hire other people as an employee. A sole Proprietorship usually does not have to do incorporation or registration compliance. </p>
                      <span className="headingH2 label primary">Who is a Proprietor?</span>
                      <p className="marginTop10">The proprietor is the owner of all assets and responsible for all debts of the business. Trade can differ from the legal name of the sole proprietor and Proprietor may trademark his trade name. The Sole Proprietor is entitled to have all profits (subject to taxation specific to the business) and has unlimited responsibility for all losses and debts. </p>
                      <div className="row">
                        <div className="cell medium-6">
                        <span className="headingH2 label primary">Registration Process</span>
                        </div>
                        <div className="cell medium-6">
                            <img src="/images/Registration-Process-Diagram-Steps-1.jpg" className="marginTop10" alt="company_registration_process" />
                        </div>
                      </div>
                      <span className="headingH2 label primary marginTop10">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Latest Address Proof of Proprietor</li>
                        <li>Proof of Registered Address</li>
                        <li>Cancelled Cheque</li>
                        <li>NOC from the Owner of Premises</li>
                        <li>Self-Attested Copy of Pan Card</li>
                        <li>Two Colour Photograph</li>
                        <li>Valid Identity Proof of Proprietor</li>
                      </ul>
                     
                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Partnership Firm Registration</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">What is a Partnership Firm? </span>
                      <p className="marginTop10">Being a popular sort of business constitution Partnership Firm is a business form that is owned, managed, and controlled by an Association of individuals for profit. Partnership firms are relatively easy to start out and prevalent amongst small and medium-sized businesses. </p>
                      <p className="marginTop10">Out of various company registration services, Firm Registration is the least complex.Low setup cost, Easy Compliances are some of the major advantages of registering a firm though liabilities of Firm are the responsibility of the Partners of the Firm and hence is not recommended for complex businesses. If someone is looking to register a new company and requires a company for Online Business Registration for e-commerce companies, Firm Registration is a nice option. Minimum Two person are required to become partners of the firm. No minimum capital is prescribed ,it must be according to the business requirements.In a partnership firm foreign investment is not permissible. In the firm,only Indian citizens can become the partner and can begin the partnership firm.There is no compulsion for Registration of Firm with Registrar of Firms. If owners want to expand their operation and wish to limit their liability, the Firm may be incorporated as a Private Limited Company or LLP (Limited Liability Partnership) at any stage.</p>
                      <span className="headingH2 label primary">What is a Partnership Deed?</span>
                      <p className="marginTop10">Partnership firms are created by drafting a Partnership deed amongst the Partners. Partnership Deed is an agreement between the partners of a firm in which the terms and conditions of partnership among the partners are framed. GST Health can help to start a registered or unregistered Partnership firm in India. </p>
                      <span className="headingH2 label primary">What is the difference between Traditional Partnership Firm and LLP?</span>
                      <p className="marginTop10">Traditional partnership firm: Every partner is liable, jointly with all other partners and also severally for all acts of the firm done while he’s a partner. LLP: Liability of the partner is restricted to his agreed contribution. All partners aren’t liable on account of the independent or unauthorized acts of other partners, thus allowing individual partners to be shielded from joint liability created by another partner’s wrongful acts or misconduct.</p>
                      <div className="row">
                        <div className="cell medium-6">
                        <span className="headingH2 label primary">Registration Process</span>
                        </div>
                        <div className="cell medium-6">
                            <img src="/images/Registration-Process-Diagram-Steps-2.jpg" className="marginTop10" alt="company_registration_process" />
                        </div>
                      </div>
                      <span className="headingH2 label primary marginTop10">Registration Process</span>
                      <span className="headingH3 display marginTop10">SELECTION OF NAME</span>
                      <p className="marginTop10">The first step is to select the name of the partnership firm which should be unique to avoid any infringement of somebody else's Trademark or registered name.</p>
                      <span className="headingH3">DEED DRAFTING</span>
                      <p className="marginTop10">The second step is to draft the Deed of Partnership which is known as the constitution of the firm which determines the relationship of partners among themselves as well as the relation of partners with the firm.</p>
                      <span className="headingH3">DEED NOTARY</span>
                      <p className="marginTop10">The third step is to get the Partnership Deed signed and notarised.</p>
                      <span className="headingH3">FIRM REGISTRATION</span>
                      <p className="marginTop10">The signed partnership agreement including the KYC of partners and premises proof is filed with the concerned Registrar of Firms, for its registration.</p>

                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Proof of Registered Office not older than 2 months ( (Electricity, Telephone Bill, Rent Agreement))</li>
                        <li>PAN Card and Aadhaar card Copy of Partners</li>
                        <li>Address Proof of Partners (Bank Statement / Electricity, Postpaid Mobile bill,Telephone Bill)</li>
                        <li>NOC from the owner of the premises</li>
                        <li>Scanned Photograph of Each Partners</li>
                        <li>Identity Proof of partners (Adhar Card/Voter ID / Driving License/ Passport)</li>
                      </ul>
                    </div>
                    <div className="tabs-panel" id="panel3v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Private Limited Company Registration</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">Private Limited Company </span>
                      <p className="marginTop10">
                          Section 2(68) of Companies Act, 2013 defines private companies as the companies whose articles of association restrict the transferability of shares and prevent the public at large from subscribing to them. This is the essential criterion that differentiates private companies from public companies. 
                            The Section further states that private companies can have a maximum of 200 members (except for One Person Companies). This number does not include present and former employees who are also members.
                            Moreover, more than two persons who own shares jointly are treated as a single member. Earlier this definition had prescribed a minimum paid-up share capital of Rs. 1 lakh, but an amendment in 2005 removed this requirement. But now private companies can have a minimum paid-up capital of any amount.
                        </p>
                      <p className="marginTop10">Private Limited Company is the most reputed form of company registration services and suitable for people who are looking to raise funds, provide ESOPs, or do Online business registration with e-commerce companies. A registered Private Limited Company is a separate legal entity, which is able to enter into contracts in its own name. Liabilities of the Private Limited Company are the responsibility of the company - not the shareholders. Moreover legal compliance for Private Limited Company is less tedious when compared to Public Limited so it is a preferred form of registering a new company. </p>
                      <span className="headingH2 label primary">What is a Partnership Deed?</span>
                      <p className="marginTop10">Partnership firms are created by drafting a Partnership deed amongst the Partners. Partnership Deed is an agreement between the partners of a firm in which the terms and conditions of partnership among the partners are framed. GST Health can help to start a registered or unregistered Partnership firm in India. </p>
                      <span className="headingH2 label primary">What is the difference between Traditional Partnership Firm and LLP?</span>
                      <p className="marginTop10">Traditional partnership firm: Every partner is liable, jointly with all other partners and also severally for all acts of the firm done while he’s a partner. LLP: Liability of the partner is restricted to his agreed contribution. All partners aren’t liable on account of the independent or unauthorized acts of other partners, thus allowing individual partners to be shielded from joint liability created by another partner’s wrongful acts or misconduct.</p>
                      <span className="headingH2 label primary">What are the types of Private Limited Company?</span>
                      <ul>
                          <li>Limited by shares: The liability of the members is limited to the amount unpaid to the company with respect to the shares held by them.</li>
                          <li>Limited by guarantee: Here the members’ liabilities are limited to the amount of money they guarantee to pay in case the company is wound-up.</li>
                          <li>Unlimited liability: The liability of members is unlimited in this type of private companies. Personal assets can be seized and sold when the company is being wound-up. Also, the small companies having limited paid-up share capitals and turnover amounts, as defined under Section 2(85), are treated as private companies under Indian company law.</li>

                      </ul>
                      <div className="row">
                        <div className="cell medium-6">
                        <span className="headingH2 label primary">Registration Process</span>
                        </div>
                        <div className="cell medium-6">
                            <img src="/images/Registration-Process-Diagram-Steps-3.jpg" className="marginTop10" alt="company_registration_process" />
                        </div>
                      </div>
                      <span className="headingH2 label primary marginTop10">What are the mandatory compliances of a Private Limited Company?</span>
                      <span className="headingH3 display marginTop10"> Board Meeting</span>
                      <ul className="marginTop10">
                          <li>The first meeting shall be held within 30 days of incorporation of the company in the presence of the Board of Directors.</li>
                          <li>In a calendar year minimum, four board meetings shall be conducted i.e. one in every three months.</li>

                      </ul>
                      <span className="headingH3">Annual General Meeting</span>
                      <ul className="marginTop10">
                          <li>It is explained under section 96 of The Companies Act, 2013 that every company is required to call at least one meeting of its shareholders each year.</li>
                          <li>The first annual general meeting of a company must be held within nine months from the date of closing of the first financial year.</li>
                          <li>After that, no meeting is necessary for the year of incorporation. The annual general meeting must take place every year for once at least.</li>
                          <li>The gap between one meeting and next should not exceed 15 months.</li>

                      </ul>
                      <span className="headingH3"> Appointment of Auditor</span>
                      <ul className="marginTop10">
                          <li>Section 139 of the Companies Act 2013, talks about the appointment of an auditor.</li>
                          <li>Every company at its first annual general meeting has to appoint an individual or a firm as an auditor.</li>

                      </ul>
                      <span className="headingH3">Statutory auditing the book of accounts by the auditor</span>
                      <ul className="marginTop10">
                          <li>The auditor has the right to access the books and accounts of the company.</li>
                          <li>A regular statutory audit shall be conducted to assess the company’s financial stability.</li>
                          <li>The accountants shall be liable for fraudulent representation made in the books of accounts.</li>
                          <li>The audited financial report shall be submitted to the Registrar of the company.</li>

                      </ul>
                      <span className="headingH3">Income Tax Annual Returns</span>
                      <ul className="marginTop10">
                          <li>A private limited company must file Income Tax Return by filling e-form MGT-7 on or before 30th November 2020 and failure to fill will result in a capital penalty of Rs 10,000.</li>
                          <li>The Annual Income Tax Return is filed in a period of 60 days from conducting annual general meetings.</li>
                          <li>The Annual Income Tax Return is calculated for the financial year with effect from 1st April to 31st March.</li>

                      </ul>
                      <span className="headingH3">Financial Statement</span>
                      <ul className="marginTop10">
                          <li>The private limited company shall file the e-form AOC-4 on the portal of the Ministry of Corporate Affairs on or before 30th November 2020.</li>
                          <li>The form has to be filed after the 30 days of the annual general meeting to the Registrar of the company.</li>
                          <li>The failure or delay in filing the AOC-4 form will attract the penalty of Rs 200 per day.</li>

                      </ul>

                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>NOC from the owner of the premises of Registered Office</li>
                        <li>Address Proof (Bank Statement / Electricity, Mobile, Telephone Bill)</li>
                        <li>Foreign national:2 Photographs</li>
                        <li>Proof of Registered Office</li>
                        <li>Foreign National: Proof of Address- Any utility bill not older than 6 months duly attested foreign notary and apostille.</li>
                        <li>PAN Card and Adhaar Card of Each Promoter and Director</li>
                        <li>Foreign National: Proof of Identity- Copy of passport duly attested foreign notary and apostille</li>
                        <li>Two Colour Photographs of Promoter and Director</li>
                        <li>Identity Proof (Voter ID / Driving License/ Passport) of Promoter and Director</li>
                        <li>Utility Bill as proof must be Latest not later than 2 months</li>
                      </ul>
                    </div>
                    <div className="tabs-panel" id="panel4v">
                      
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Limited Liability Partnership Registration</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">What is a Limited Liability Partnership Registration? </span>
                      <p className="marginTop10">
                            It is the form of corporate business that incorporates professional expertise and entrepreneurial initiative for combining and operating in a flexible, innovative, and efficient manner, providing benefits of limited liability while giving flexibility to its members for organizing their internal structure as a partnership. LLP is a legal entity partnership act. 
                        </p>
                        <p className="marginTop10">
                            The Parliament of India has enacted The Limited Liability Partnership Act, 2008, and legally sanctions the concept of LLP in India. LLP is defined as a body corporate and legal entity distinct from its partners having perpetual succession and any change in the partners shall not affect the existence, rights, or liabilities of the LLP.
                        </p>
                        <p className="marginTop10">
                            Rights and duties are made through an agreement between the partners or between the partners and the LLP as the case may be. It is not relieved of the liability for its other obligations as a separate entity.LLP is called a hybrid between a company and a partnership as it contains elements of both ‘a corporate structure’ as well as ‘a partnership firm structure’.
 
                        </p>
                      <div className="row">
                        <div className="cell medium-6">
                        <span className="headingH2 label primary">Registration Process</span>
                        </div>
                        <div className="cell medium-6">
                            <img src="/images/Registration-Process-Diagram-Steps-4.jpg" className="marginTop10" alt="company_registration_process" />
                        </div>
                      </div>
                      <span className="headingH2 label primary marginTop10">What is the difference between Traditional Partnership Firm and LLP?</span>
                      <p className="marginTop10">Traditional partnership firm: Every partner is liable, jointly with all other partners and also severally for all acts of the firm done while he’s a partner.
                        <span><i>LLP:</i></span> The liability of the partner is restricted to his agreed contribution. All partners aren’t liable on account of the independent or unauthorized acts of other partners, thus allowing individual partners to be shielded from joint liability created by another partner’s wrongful acts or misconduct. </p>
                      <span className="headingH2 label primary">What is the difference between LLP and Company?</span>
                      <p className="marginTop10">1. A normal difference between an LLP and a joint-stock company lies in that the internal governance structure of a company is regulated by statute (i.e. Companies Act, 2013) whereas for an LLP it might be by a contractual agreement between partners.</p>
                      <p className="marginTop10">2. LLP is more flexible and has lesser compliance requirements as compared to a company.</p>     
                      
                      <span className="headingH2 label primary">What are the Annual Compliances of LLP?</span>
                      <ul className="marginTop10">
                          <li>Filing of Annual accounts and solvency statements with Registrar of Companies in Form 8 before 30th October every year.</li>
                          <li>Filing of Annual Return in Form 11 before 30th May every year.</li>
                          <li>Statutory audit if turnover crosses Rs 40 lakh or contribution exceeds Rs 25 lakh.</li>
                          <li>If turnover exceeds Rs 1 crore in case of business and Gross receipts exceed Rs 25 lakh in case of profession, Due date for Tax audit is before September 30.</li>
                          <li>Due date for income tax return is July 31, but if your LLP is covered under tax audit additional three months’ time is given and the due date, in this case, is September 30.</li>
                          <li>First general meeting to be conducted within 30 days of incorporation of LLP.</li>
                          <li>Minimum one general meeting is conducted during the financial year of LLP.</li>
                          <li>In the case of the Executive committee, at least 2 executive meetings is conducted in case of LLP.</li>


                      </ul>

                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Identity Proof of partners (Voter ID / Driving License/ Passport).</li>
                        <li>Address Proof of partners (Bank Statement / Electricity, Mobile, Telephone Bill).</li>
                        <li>NOC From the owner of the premises.</li>
                        <li>PAN Card and Aadhar Card of Each Partner.</li>
                        <li>Utility Bill as Proof of Registered Address of partners.</li>
                        <li>Scanned Colour Photographs of Each Partner</li>
                      </ul>

                    </div>
                    <div className="tabs-panel" id="panel5v">
                      
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">One Person Company Registration</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">What is One Person Company in India?</span>
                      <p className="marginTop10">
                            Companies Act 2013 has introduced a new concept of “One Person Company”(OPC). It is formed with only one person as its member. Such companies enjoy certain privileges or exemptions as compared to other companies.
                        </p>
                        <p className="marginTop10">
                            “One Person Company” means a company which has only one person as a member. This Company is suitable for small businesses where the turnover is not likely to cross Rs. 2 Crores and the maximum amount of capital to be invested is limited to Rs. 50 Lac. 
                            Apart from giving a single promoter full control over the company, One Person Company also limits the liability of the owner. Online company registration of One Person Company (OPC) is very simple and it offers various advantages to owners. One Person Company has no scope of raising equity funding or offering employee stock options. OPC can be converted into Private or Public Limited Company. Holding Board Meetings is an exemption to this form of company.

                        </p>

                      <span className="headingH2 label primary">Can OPC have more than one director?</span>
                      <p className="marginTop10">An OPC can have more than one director. Limitation of OPC is that only an Indian Citizen can open an OPC Company and FDI is not allowed in One Person Company.</p>
                      <div className="row">
                        <div className="cell medium-6">
                        <span className="headingH2 label primary">Registration Process</span>
                        </div>
                        <div className="cell medium-6">
                            <img src="/images/Registration-Process-Diagram-Steps-5.jpg" className="marginTop10" alt="company_registration_process" />
                        </div>
                      </div>
                      <span className="headingH2 label primary marginTop10">Registration process</span>
                      <span className="headingH3 display marginTop10"> DIGITAL SIGNATURE</span>
                      <p className="marginTop10">The application process starts with the issuance of the digital signature of class-3 for the sole promoter and the nominee for processing the incorporation.</p>
                      <span className="headingH3">COMPANY NAME APPROVAL</span>
                      <p className="marginTop10">The Company must be incorporated with a unique and new name that should not be similar to an already registered company. This application for name reservation is submitted to the MCA and the name of the OPC must end or include the words OPC.</p>
                      <span className="headingH3"> INCORPORATION </span>
                      <p className="marginTop10">The next step after obtaining the name approval is incorporation application to be filed with MCA in Form INC-3. In this application signed MOA and AOA are to be attached and on filing, for incorporation application, the approval is granted by the Registrar of Companies (ROC).</p>
                      <span className="headingH3">COMMENCEMENT OF BUSINESS</span>
                      <p className="marginTop10">After completing the above step, the incorporation certificate is obtained and the one person company can now proceed for opening the bank account. Then the promoter must deposit the amount mentioned in the MOA of the company. Companies can file for the commencement of business after depositing the amount in bank and the certificate for commencement of business must be obtained within 180 days of incorporation.</p>
                      
                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Address Proof of Promoter and Director (Bank Statement / Electricity, Mobile, Telephone Bill)</li>
                        <li>Scanned Photographs of Director and Promoter.</li>
                        <li>PAN Card of Promoter and Director.</li>
                        <li>NOC from the owner of the premises of Registered Office.</li>
                        <li>Identity Proof Promoter and Director (Voter ID / Driving License/ Passport).</li>
                        <li>Utility Bill as proof must be Latest not later than 2 months.</li>
                      </ul>
                    </div>
                    <div className="tabs-panel" id="panel6v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Public Limited Company Registration</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">What is a Public Limited Company?</span>
                      <p className="marginTop10">
                            As per the Company Act, the company which is not a private company is known as a public company. A private company, subsidiary of a public company shall be deemed to be a Public Company.Out of Various forms of Online Business Registration, Public Limited Company is the most prestigious. Online Business Registration is also the most complex in the case of Limited Company. A separate legal entity, Limited Company is able to enter into contracts on its own name. Liabilities of the Company are the responsibility of the company not the shareholders. Public Limited Company finds it easier to raise funds from Financial Institutions and General Public owing to stricter compliances it follows. Though it is not advisable when someone has to register a new company.Only Public Listed companies are allowed to list on stock exchange.
                        </p>
                        <div className="row">
                          <div className="cell medium-6">
                          <span className="headingH2 label primary">Registration Process</span>
                          </div>
                          <div className="cell medium-6">
                              <img src="/images/Registration-Process-Diagram-Steps-6.jpg" className="marginTop10" alt="company_registration_process" />
                          </div>
                        </div>
                        <span className="headingH2 label primary marginTop10">What is the Minimum requirement of shareholders in a Public Limited company?</span>
                        <p className="marginTop10">
                            The minimum requirement for a Public Company is seven shareholders and three directors and no restriction on the maximum shareholder.
                        </p>
                        <p className="marginTop10">
                            Through an initial public offering (IPO), public companies can issue securities to the general public and a public company can only be listed on stock exchanges. These companies are subject to higher levels of reporting, regulations, and public scrutiny. A listed public company must meet stringent reporting requirements framed by the Securities and Exchange Board of India (SEBI). 
                        </p>

                      <span className="headingH2 label primary">Is the Public Limited Company the same as a Private Limited Company?</span>
                      <p className="marginTop10">A Public Ltd. company has all the characteristics of Private Ltd. Co. and the ability to possess any number of members, ease in transfer of shareholding, and more transparency. Identifying marks of Public Ltd. Co. is through name, shares, formation, a number of members management, directors and meetings, etc.</p>

                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Passport in case of foreign nationals.</li>
                        <li>Residence Proof (Electricity Bill/Telephone Bill/Mobile Bill/Bank Statement).</li>
                        <li>Income-tax PAN of Indian nationals.</li>
                        <li>Identity Proof (Voter ID/Aadhar Card/Driving License/Passport).</li>
                        <li>Registered Office address proof (rent agreement).</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default StartBusiness
