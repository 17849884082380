import React from 'react'

function PFAndForms() {
    return (
        <div className="row">
        <div className="grid-container tabsContentContainer">
            <div className="grid-x">
                <div className="cell large-12 small-12">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">PF Registration & Forms</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                    
                    <p className="marginTop10">
                       
                        Employee's Provident fund is a social security scheme governed by the Employee's Provident Funds and Miscellaneous Provisions Act, 1952. PF registration helps employees to save a small portion of their salary for future benefit. It is applicable for all establishments where 20 or more persons are employed. The employee gets a total amount including self and employee's contribution with interest on retirement or resignation.

                    </p>

                    <span className="headingH2 label primary marginTop10">Eligibility Criteria for PF Registration</span>
                    
                    {/* <span className="headingH3 display marginTop10">Shop License</span>
                    <p className="marginTop10">
                        Shop license is needed for those who would like to open a shop or an establishment to sell the products.
                    </p>

                   
                    <span className="headingH3 display marginTop10">Industrial License</span>
                    <p className="marginTop10">
                        Industrial license is required for a company who is planning to open a small or mid-sized industrial unit.
                    </p>

                    <span className="headingH3 display marginTop10">Food Establishment License</span>
                    <p className="marginTop10">
                        Any establishment that plans for the food and beverage industry needs this license.This license applies to restaurants, cafes, meat shops, bakeries and vegetable shops.
                    </p> */}
                    <span className="headingH3 display marginTop10">An organization must fulfill the following criteria for PF registration:</span>
                    <p className="marginTop10">
                        <ul>
                            <li>Factory with employee strength of 20 or more.</li>
                            <li> An establishment employs more than 20 persons and the Central Government  defines the class of such firms.</li>
                            <li>An establishment having less than 20 workers and has been notified of compulsory registration for not less than 2 months.</li>
                            <li>There must be mutual agreement to apply for PF to the Central PF Commissioner between employer and the employees of an establishment.</li> 
                        </ul>
                    </p>
                    <span className="headingH3 display marginTop10">Benefits of PF Registration Online</span>
                    <p className="marginTop10">
                        <ul>
                            <li>Pension coverage.</li>
                            <li> Covering the financial risk.</li>
                            <li>Single account which can be transferred while switching jobs.</li>
                            <li>Emergency fund</li> 
                            <li>Checking of the EPF balance</li>
                        </ul>
                    </p>
                    <span className="headingH3 display marginTop10">Amount of PF contribution</span>
                    <p className="marginTop10">
                        <ul>
                            <li>12% of basic salary + dearness allowance + retaining allowance is the PF contribution paid by the employer.</li>
                            <li>Out of the employer's contribution, 8.33% will be routed to Employee's Pension Scheme.</li>
                            <li>The employer before paying the employee’s salary must deduct the employee's contribution from his wages and that portion is payable to the EPFO within 15 days of the close of every month.</li>
                        </ul>
                    </p>
                    <span className="headingH2 label primary marginTop10">PF Return Filing</span>
                    <p className="marginTop10">
                        All employers having PF registrations are required to file returns on a monthly basis. Returns filing must be completed by the 25th of each month. The various forms through which the purpose must be fulfilled are as follows:
                    </p>
                    <span className="headingH3 display marginTop10">Form 2</span>
                    <p className="marginTop10">
                        It is filed for the purpose of declaration and nomination under the schemes of Employees Provident Fund and Employees Family Pension. It is filed when a person joins an entity. This form is submitted along with Form 5. Form 2 is divided into two parts:
                    </p>
                    <span className="headingH3 display marginTop10">Part A</span>
                    <p className="marginTop10">
                        This Part deals with nominating the recipients of the EPF balance of a particular account holder in the event of his/her death. The following details of the nominee must be provided and signed:
                    </p>
                        <ul>
                            <li>Name of Nominee.</li>
                            <li>Address of Nominee.</li>
                            <li>About Relationship with the subscriber.</li>
                            <li>Age of the Nominee.</li>
                            <li>Sum assured to be paid to the nominee.</li>
                            <li>Guardian details of the nominee.</li>
                        </ul>
                    <span className="headingH3 display marginTop10">Part B</span>
                    <p className="marginTop10">
                     It includes the details of the nominee as already specified. It also includes the details of the family members who are eligible to receive the children/ widow pension must be furnished.
                    </p>
                    <span className="headingH3 display marginTop10">Form 5</span>
                    <p className="marginTop10">
                        Under this Form monthly report is prepared that includes information related to the employees who have been newly enrolled into the provident fund scheme. This form must be filed and stamped by the employer with the date of the filing form. Details of organization required are as follows:
                    </p>
                        <ul>
                            <li>Name.</li>
                            <li>Address.</li>
                            <li>Code Number.</li>
                            <li>Bank Details of employee.</li>
                            <li>Date of Birth of an employee.</li>
                            <li>Date of joining.</li>
                            <li>Track record of work.</li>
                        </ul>
                    <span className="headingH3 display marginTop10">Form 10</span>
                    <p className="marginTop10">
                        It is a monthly report that contains details of the employees who have ceased to be a part of the scheme on a given month. Details to be filled in the Form are:
                    </p>
                        <ul>
                            <li>Bank Details.</li>
                            <li>Employee's Name.</li>
                            <li>Name of the father or husband.</li>
                            <li>Date of leaving service.</li>
                            <li>Reason for leaving service.</li>
                        </ul>
                    <span className="headingH3 display marginTop10">Form 12A</span>
                    <p className="marginTop10">
                        It includes the details of the payments contributed to the account of the respective employee in a particular month.
                    </p>

                    <span className="headingH2 label primary marginTop10">Annual PF Return Filing</span>
                    <p className="marginTop10">
                        One should keep in mind the compliances date for Annual returns and file before the 30th of April in a given year. The form available for filing the annual return is Form 3A and Form 6A.
                    </p>
                    <span className="headingH3 display marginTop10">Form 3A</span>
                    <p className="marginTop10">
                        This Form is also known as a member’s annual contribution card, Form 3A includes the month-wise contributions made by the subscriber/member and employer towards EPF and Pension Fund in a particular year. The details of the scheme will include the following:
                    </p>
                        <ul>
                            <li>Bank Details.</li>
                            <li>Subscriber's Name.</li>
                            <li>Name of the father or husband.</li>
                            <li>Name and address of the factory/establishment.</li>
                            <li>The statutory rate of contribution.</li>
                            <li>Voluntary contribution rate, if any.</li>
                        </ul>
                    <span className="headingH3 display marginTop10">Form 6A</span>
                    <p className="marginTop10">
                        This is a consolidated annual contribution statement that includes details of the annual contributions of each member of the organization. Details required in Forms are as follows:
                    </p>
                        <ul>
                            <li>Bank Details.</li>
                            <li>Name of the subscriber.</li>
                            <li>Wages, Retaining allowance (if any), and D.A.</li>
                            <li>Amount of worker’s contribution deducted from the wages.</li>
                            <li>EPF.</li>
                            <li>Refunds of advances.</li>
                            <li>Rate of a higher voluntary contribution.</li>
                            <li>Remarks.</li>
                        </ul>
                    <p className="marginTop10">
                        Amount remitted column must be filled with the following details:
                    </p>
                        <ul>
                            <li>Contribution details month-wise.</li>
                            <li>Remitted contribution including a refund of advances.</li>
                            <li>Pension fund contribution.</li>
                            <li>EDLI contribution.</li>
                            <li>Administration charges.</li>
                            <li>Aggregate contributions.</li>
                        </ul>
                    <span className="headingH2 label primary marginTop10">Annual Account Statement</span>
                    <p className="marginTop10">
                        The Employees Provident Fund Organization (EPFO) is regulated to send the annual statement of accounts to each subscriber through his/her employer.in a statement of accounts you can access the following information:
                    </p>
                        <ul>
                            <li>Opening balance of contribution.</li>
                            <li>Annual contribution.</li>
                            <li>Interest earned on contributions.</li>
                            <li>Total number of contributions made by the employer & employee.</li>
                        </ul>

                </div>
            </div>
        </div>
  </div>
    )
}

export default PFAndForms
