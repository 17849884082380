import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const GstService = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="startBiz-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">GST Registration</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">GST Invoicing/ Eway Bill</a></li>
                    <li className="tabs-title"><a href="#panel3v" onClick={refreshPage} className="headingH3">GST Returns</a></li>
                    <li className="tabs-title"><a href="#panel4v" onClick={refreshPage} className="headingH3">GST LUT Filling</a></li>
                    <li className="tabs-title"><a href="#panel5v" onClick={refreshPage} className="headingH3">GST Registration for foreigner</a></li>
                    <li className="tabs-title"><a href="#panel6v" onClick={refreshPage} className="headingH3">Input Tax Credit</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="startBiz-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">GST Registration</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">From 1st July 2017, Government of India launched GST, a Tax reforms which will change the landscape of Taxation in India forever. GST replaced all the existing taxes and brought a uniform tax structure for the whole country. GST is an all-in-one tax that subsumes a variety of state and central taxes. GST Registration is applicable for manufacturing companies, trading companies, service companies, IT companies, freelancers etc. </p>
                      <p className="marginTop10">Any supplier who carries on any services anywhere in India &amp; whose aggregate turnover exceeds Rs.20 lakhs and for the supply of goods exceeds Rs. 40 lakhs (w.e.f 1 April 2019) during a financial year is liable to get himself registered and obtain GSTIN. But, the mentioned limit is reduced to Rs 10 lakhs to the states specified in Article 279A (4)(g) of the constitution viz. Arunachal Pradesh, Assam, Jammu and Kashmir, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura, Himachal Pradesh, and Uttarakhand. </p>
                      <p className="marginTop10">Goods and Services Tax Identification Number (GSTIN) or GST Number is termed as the unique identifier assigned to a business or person registered under the GST Act. </p>
                      
                      <p className="marginTop10" style={{fontWeight:"bold"}}>It is necessary to obtain GSTIN in case of a certain category of persons. The important categories are as follows:</p>
                      <p className="marginTop10">1. Persons who are required to pay tax under Reverse Charge Mechanism (RCM) or Persons who are required to deduct tax under GST (TDS).</p>
                      <p className="marginTop10">2. Persons who supply goods and/or services on behalf of other registered taxable persons whether as an agent or otherwise.</p>     
                      <p className="marginTop10">3. Input service distributor.</p>
                      <p className="marginTop10">4. Casual taxable persons or Non-resident taxable persons.</p>     
                      <p className="marginTop10">5. Electronic commerce operators required to pay tax under sub-section (5) of section 9.</p>
                      <p className="marginTop10">6. Electronic Commerce aggregator.</p>     
                      <p className="marginTop10">7. Every person supplying online information and database retrieval services from a place outside India to a person in India, other than a registered person.</p>
                      
                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Note: List of documents may vary according to the type of business.</li>
                        <li>Address proof of Business (Electricity Bill, Telephone bill, Rent agreement etc).</li>
                        <li>PAN card of the owner.</li>
                        <li>Photograph of the owner.</li>
                        <li>Aadhar card of the owner.</li>
                        <li>Bank account details</li>
                     </ul>
                     
                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">GST Invoicing/ Eway Bill</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">GST Invoice </span>
                      <p className="marginTop10">A GST invoice refers to a document that contains all the relevant details of a business transaction and both the parties involved. It should include all details like product name, description, quantity, details of the supplier and the purchaser, terms of sale, the rate charged, discounts, etc. </p>
                      <span className="headingH2 label primary">Requirement Of GST Invoice</span>
                      <p className="marginTop10">The government of India has also provided a sample GST invoice format. So it is good to issue a GST invoice on the same guidelines. </p>
                      <p className="marginTop10" style={{fontWeight:"bold"}}>The crucial information as explained must be stated in GST Invoice:</p>
                      <ul className="lodoc marginTop10">
                        <li>Name, address and the GSTIN of the supplier</li>
                        <li>Nature of Invoice</li>
                        <li>Date of invoice</li>
                        <li>Name, address and the GSTIN of the recipient</li>
                        <li>Description of goods or services</li>
                        <li>HSN code of the goods ( Not required upto turnover of Rs. 1.5cr, Turnover between 1.5 -5 crores can use 2-digit HSN code, Turnover above 5 crores must use 4-digit HSN code</li>
                        <li>Rate of GST</li>
                        <li>Place of delivery/supply</li>
                        <li>Total value of the goods or services</li>
                        <li>Signature in physical form or digital signature of the supplier</li>
                        <li>Bill of supply</li>
                      </ul>
                      <p className="marginTop10">When the supplier is registered under the composition scheme, then he has to issue a Bill of Supply instead of a GST invoice.</p>
                      <span className="headingH2 label primary">Supplementary Invoice/Debit Note</span>
                      <p className="marginTop10">Whenever there is an upward revision in prices of a good or service supplied earlier and the same was chargeable to GST, then the supplier is liable to issue a supplementary invoice to the recipient. It should be issued within 30 days from the date of such price revision. </p>
                      <span className="headingH2 label primary">Credit note</span>
                      <p className="marginTop10">Whenever there is downward revision in price, credit note is issued on or before 30th September of the next financial year or before filing the annual return of GST, whichever is earlier. </p>
                      <span className="headingH2 label primary">Cross –Referencing of Invoices</span>
                      <p className="marginTop10">As invoice is one of the crucial parts in claiming credit for the GST paid, so it is necessary to upload returns on time so that credit flows to the end customer. </p>
                      
                      <p className="marginTop10" style={{fontWeight:"bold"}}>Raising Copies of Invoice</p>
                      <p className="marginTop10">The invoice must be prepared in triplicate. They will be clearly marked as:</p>

                      <p className="marginTop10">1. Original Copy for the use of the recipient.</p>
                      <p className="marginTop10">2. Duplicate Copy for the use the transporter.</p>     
                      <p className="marginTop10">3. Triplicate Copy for the use of the supplier.</p>
                      <p className="marginTop10" style={{fontWeight:"bold"}}>Invoices for Supply of Services</p>
                      <p className="marginTop10">The invoice must be prepared in duplicate and clearly marked as:</p>
                      <p className="marginTop10">1. Original Copy for the use of the recipient.</p>
                      <p className="marginTop10">2. Duplicate Copy for the use the supplier.</p>     
                      
                      <span className="headingH2 label primary">Eway Bill</span>
                      <p className="marginTop10">
                          Electronic Way Bill is known as E-Way Bill. Under GST, it is a document/bill, which is electronically generated for the movement of goods from one place to another, either interstate or intrastate. Separate unique Eway bill number is allocated to the supplier, recipient and the transporter after E-way bill generation. Eway bill is covered under section 68 of the CGST Act & Rule 138 of the CGST rules, 2017 which states that every person registered under GST, who causes the movement of goods of consignment value more than Rs 50,000 is required to furnish the details of the said goods in a specified form namely eway bill before commencement of such movements of goods.
                      </p>
                      <p className="marginTop10">
                          Most of the states want to implement their own e-way bill systems which can create problems in the future.
                      </p>
                    </div>

                    <div className="tabs-panel" id="panel3v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">GST Returns</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <span className="headingH2 label primary marginTop20">Monthly Returns</span>
                      <p className="marginTop10">
                        In the new system of GST return filing, a taxpayer needs to file form GST RET-1 (Normal) or Form GST RET-2 (Sahaj) or Form GST RET-3 (Sugam) on either monthly or quarterly basis. A taxpayer with a turnover of up to Rs 5 crores can file GST returns on a quarterly basis.
                        </p>
                      <span className="headingH2 label primary">Annual Returns</span>
                      <p className="marginTop10">GST returns include two types of returns - periodic and annual returns. The periodic return is monthly or quarterly returns for reporting transactions during the month or quarter, while the annual return is for reporting the summary of the periodic returns filed during a financial year. It is necessary for a business to file this return annually by 31st December of the coming financial year (FY). It comprises the amount of taxes paid (CGST, SGST, IGST) during the year, as well as details of exports or imports.</p>
                      
                      <ul>
                          <li>It consists of details related to outward and inward supplies received or made in the relevant previous year under CGST, SGST, IGST and HSN codes.</li>
                          <li>It provides transparency disclosures of monthly/ quarterly returns.</li>
                          <li>It should be filed by taxable persons, input service distributors, non- resident taxable persons, taxpayers opting for composition schemes and persons paying TDS.</li>

                      </ul>
                      
                      <span className="headingH2 label primary">Advantages</span>
                      <ul>
                          <li>There is no late fee charged on IGST.</li>
                          <li>This form provides disclosure of annual sales by stating what is subject to  tax and not subject to tax.</li>
                          <li>It also reveals the annual value of inward supplies.</li>
                          <li>Purchases are to be classified as inputs, inputs services and capital goods.</li>

                      </ul>
                      <span className="headingH2 label primary">Disadvantages</span>
                      <ul className="marginTop10">
                          <li>The first meeting shall be held within 30 days of incorporation of the company in the presence of the Board of Directors.</li>
                      </ul>
                    </div>

                    <div className="tabs-panel" id="panel4v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">GST LUT Filling</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      
                      <p className="marginTop10">
                            LUT refers to the Letter of Undertaking under GST. The Letter of Undertaking (LUT) documents need to be furnished in form GST RFD 11 under rule 96A, where the exporter declares that he/she would fulfill all the requirements while exporting without making IGST payment. It is mandatory that all registered taxpayers who export the goods or services have to furnish a Letter of Undertaking (LUT) in the GST RFD-11 form on the common portal of GSTN.
                      </p>

                      <span className="headingH2 label primary">Features of LUT Filing</span>
                      <ul className="marginTop10">
                          <li>There is an eligibility criteria to apply LUT under GST. A person going to supply goods/services to or outside India, places covered under special economic zone, registered under GST and without paying integrated tax.</li>
                          <li> LUT is accepted by the Assistant or Deputy Commissioner having jurisdiction over the place where the exporter performs his business.</li>
                      </ul>
                      
                      <span className="headingH2 label primary">Transparency</span>
                      <p className="marginTop10">
                        The process of export under GST LUT has now been rationalized and more transparent. Now from exporter to all stakeholders can easily have the updates of the filing process.
                      </p>
                      <span className="headingH2 label primary">Simple and Easy</span>
                      <p className="marginTop10">
                        The process has been made very simple and easy to understand through an online portal.
                      </p>

                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Self-declaration stating conditions of LUT.</li>
                        <li>Bank Guarantee.</li>
                      </ul>

                    </div>
                    <div className="tabs-panel" id="panel5v">
                      
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">GST Registration for foreigner</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">
                        GST was implemented in India on 1st July 2017. If a person or company is situated outside India, but performing any business transaction in India, is compulsorily required to obtain GST registration in India, as per the section 24 of the Central GST Act of 2017. It is mandatory for non- resident taxable persons to appoint an authorized person in India for the purpose of complying with GST regulations. So the application of GST registration for foreign non- resident taxable persons must be drafted and filed by an authorized agent in India.
                        </p>
                        <span className="headingH2 label primary">Features</span>
                      <ul className="marginTop10">
                        <li>It will be applicable on the supply of goods or services which replaces the existing system of manufacture of goods or sales of goods or provision of services.</li>
                        <li>GST structure is made on the principle of destination based consumption as compared to origin based taxation.</li>
                        <li>Dual based GST with central and state level.</li>
                        <li>Import of goods and services will be included in IGST, with addition to applicable custom duties.</li>
                      </ul>

                      <span className="headingH2 label primary">Advantages</span>
                      <span className="headingH3 display marginTop10"> Ease of business</span>
                      <p className="marginTop10">GST combines all products and services under one roof, unlike VAT and Service tax hence making compliance is easy for the foreigners also.</p>
                      
                      <span className="headingH3 display marginTop10"> ITC</span>
                      <p className="marginTop10">Input Tax Credit (ITC) can be claimed for taxes paid on purchases and can be utilized for payment of due taxes.</p>
                      <span className="headingH3">Low Tax Rates</span>
                      <p className="marginTop10">GST is levied only if the annual turnover of the business is more than Rs 20 lacs or Rs. 10 lacs in a certain part of the country.</p>
                      
                      <span className="headingH2 label primary">LIST OF DOCUMENTS</span>
                      <ul className="lodoc marginTop10">
                        <li>Notarized or Apostilled copy of passport for foreign nation and NRI.</li>
                        <li>Specimen signature (Blank document with signature).</li>
                        <li>Scanned copy of Voter’s ID/Passport/Driver’s License.</li>
                        <li>Scanned passport sized photograph.</li>
                      </ul>
                    </div>
                    <div className="tabs-panel" id="panel6v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Input Tax Credit</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                            ITC is also known as Input Tax Credit, it is the tax paid by the business on a purchase and it can be used to reduce its tax liability when sales are made. In general, the tax liability of businesses can be reduced by claiming credit to the extent of GST paid on purchases.
                        </p>
                        
                        <p className="marginTop10">
                            We all know that GST is an integrated tax system where every purchase by a business should be matched with the sale of another business.
                        </p>

                      <ul className="marginTop10">
                        <li>You should have a tax invoice of purchase or debit note issued by a registered dealer.</li>
                        <li>You should have received the goods/services.</li>
                        <li>Now the tax charged on your purchases has been deposited to the government by the supplier in cash or through claiming input credit.</li>
                        <li>The supplier needs to file GST returns.</li>
                        <li>ITC will only be allowed if the supplier has deposited the tax he collected from you. So it is necessary to match input credit before claiming.</li>
                        <li>ITC shall be reversed if invoice of supplier remains unpaid for 180 days.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default GstService
