import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const EsciRegistration = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">ESIC Registration</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <span className="headingH2 label primary marginTop10">ESI Registration</span>
                        <p className="marginTop10">
                            Employee State Insurance (ESI) is managed by the Employee State Insurance Corporation, an autonomous body created by the law under the supervision of the Ministry of Labour and Employment, Government of India. ESI is applicable for factories in which ten or more persons are employed. But currently, any factory or establishment employing ten or more persons drawing wages of upto Rs 21000 p/m must obtain ESI registration.
                        </p>

                        <span className="headingH2 label primary marginTop10">ESI Registration- Number of employees</span>
                        <p className="marginTop10">
                            Under ESI registration, the below mentioned categories of persons are to be counted in addition to the persons directly employed:
                        </p>

                        <ul className="marginTop10">
                            <li>Persons on the roll of a factory, who are on leave with or without wages</li>
                            <li>A substitute or badly worker employed for wages</li>
                            <li>Directors who are on the payroll of a company</li>
                            <li>Persons drawing over Rs 21000 per month as wages, but not coverable otherwise</li>
                            <li>Persons employed by or through a contractor and working under the direct supervision of the employer</li>
                        </ul>

                        <p className="marginTop10">
                            Apprentices or trainees undergoing training and learning the trade under an approved scheme will not be counted.
                        </p>

                        <span className="headingH2 label primary">Benefits of ESI registration for Employee</span>
                        <ul className="marginTop10">
                        <li>Medical Care benefit to an employee and his family members.</li>
                        <li>Sickness benefit where cash compensation at the rate of 70% of wages is payable to insured workers during the periods of certified sickness for a maximum of 91 days in a year.</li>
                        <li>Maternity benefit for pregnancy is provided for three months which is extendable by further one month on medical advice at the rate of full wage subject to contribution for 70 days in the preceding year.</li>
                        <li>Disability Benefit from the day of entering insurable employment and irrespective of having paid any contribution, 90% of wage is payable so long as temporary disability continues. And in case of permanent disablement benefit is payable at the rate of 90% of wage in the form of monthly payment to the extent of loss of earning capacity as certified by a Medical Board.</li>
                        <li>Benefit to dependents in case the death of an employee happens while on work 90% of the salary is given to his dependents every month after the death of the employee.</li>
                        <li>Old age care medical expenses.</li>
                        <li>Funeral expenses include an amount of Rs. 10,000 which is payable to the dependents or to the person who performs last rites from day one of entering insurable employment.</li>
                      </ul>
                      <span className="headingH2 label primary marginTop10">Returns to be filed every year</span>
                      <p className="marginTop10">
                            After the registration ESI Returns have to be filed twice a year. The following documents are required for the filing of the returns:
                        </p>
                        <ul className="marginTop10">
                            <li>Register of attendance of the employees</li>
                            <li>Form 6- Register</li>
                            <li>Register of wages</li>
                            <li>Register of any accidents which have happened on the premises of the business</li>
                            <li>Monthly returns and challan</li>
                      </ul>
                        
                    </div>
                </div>
            </div>
      </div>
    )
}

export default EsciRegistration
