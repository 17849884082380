import React from 'react';

// function refreshPage() {
//   window.location.reload(false);
// }

const regularCompliance = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">List of Regular Compliance</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <span className="headingH2 label primary marginTop20">KYC of company (INC-22A)</span>
                        <p className="marginTop10">
                            INC -22A is a KYC Form for companies. The purpose of the Ministry of Corporate affairs for this form is to detect Shell Companies and update the information of all the active companies. In efforts to reduce unauthorised activities, the Ministry of Corporate Affairs plans to gather the KYC details of companies, chartered accountants, cost accountants, and company secretaries.
                        </p>
                        <span className="headingH2 label primary marginTop20">KYC of Director (DIR-3)</span>
                        <p className="marginTop10">
                            According to MCA, any director who was allotted a DIN by or on 31 March 2018 and whose DIN is in approved status will need to submit his KYC details to the MCA. And this process is compulsory for the disqualified directors also.
                        </p>
                        <span className="headingH2 label primary marginTop20">Return of Beneficially Interest (BEN-2)</span>
                        <p className="marginTop10">
                            Form BEN-2 that spells out the format in which companies will have to disclose their ‘Significant Beneficial Owners’ (SBO) to the Registrar of Companies (RoCs). Through this Form, we are able to identify the real owner of the Company. Form BEN-2 needn’t be filed if there’s only direct holding of owners within the Company. Once we File Form BEN-2, SBO (Significant Beneficial Owner) ID is going to be generated by the MCA System.
                        </p>
                        <span className="headingH2 label primary marginTop20">Return of deposits (DPT-3)</span>
                        <p className="marginTop10">
                            It is a form to fill in the details of deposits, particulars of transactions not considered as deposit as per rule 2(1)(c) of the Companies (Acceptance of Deposit) Rules, 2014 or both. Every company except government companies, banking companies, and NBFC have to file form DPT-3. Accordingly, if you’re falling under any of the following categories then you’re required to file the Form DPT-3 :
                        </p>
                        <p className="marginTop10">
                            <span style={{fontWeight:"700"}}>Private Limited Company</span> <br/>
                            <span style={{fontWeight:"700"}}>Public Limited Company</span> <br/>
                            <span style={{fontWeight:"700"}}>One Person Company (OPC)</span> <br/>
                            <span style={{fontWeight:"700"}}>Section 8 Company </span> <br/>
                            <span style={{fontWeight:"700"}}>Small Company</span> <br/>
                        </p>
                        <span className="headingH2 label primary marginTop20">MSME Return</span>
                        <p className="marginTop10">
                            MSME refers to Micro, Small and Medium Enterprises Development. Business with a turnover of over Rs 75 crore but up to Rs 250 crore would be deemed as a 'medium enterprise'.
                        </p>
                       
                    </div>
                </div>
            </div>
      </div>
    )
}

export default regularCompliance
