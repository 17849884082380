import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const HRManage = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="windingup-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">Payroll Outsourcing</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">HR Documents</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="windingup-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">Payroll Outsourcing</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">
                        Payroll is one of the important functions of a company.  Payroll information is critical and highly sensitive. Onfiling offers cost-effective and confidential service with regards to payroll outsourcing.
                        </p>
                      <span className="headingH2 label primary">Computation of monthly salaries including reimbursements</span>
                      <p className="marginTop10">
                        GST Health processes salary, reimbursements and deductions for employees and gives you the final payslip figure.
                        </p>
                        <span className="headingH2 label primary">Computation of withholding taxes</span>
                      <p className="marginTop10">
                        We compute TDS and other statutory deductions while processing monthly pay.
                        </p>
                        <span className="headingH2 label primary">Web enabled payslips</span>
                      <p className="marginTop10">
                        Onfiling prepares and uploads encrypted and password protected payslips which can then be downloaded by employees.
                        </p>
                        <span className="headingH2 label primary">Bank Advice Statement</span>
                      <p className="marginTop10">
                        GST Health prepares bank advice statements on a monthly basis
                        </p>
                        <span className="headingH2 label primary">Money Movement Services</span>
                      <p className="marginTop10">
                        GST Health takes care of the entire payroll operation by offering money movement services as well. We will ensure that salaries are disbursed to individual employee accounts every month.
                        </p>
                        <span className="headingH2 label primary">Query Management Services</span>
                      <p className="marginTop10">
                        GST Health enriches the HR offering by providing responsive query management services. Any query related to payroll, tax computations or reimbursements is handled by Onfiling through email/web based query handling services. GST Health offers onsite helpdesks to handle payroll, PF &amp; ESI related queries.
                        </p>
                        <span className="headingH2 label primary">Labour Compliance Return & Management</span>
                      <p className="marginTop10">
                      GST Health along with payroll manages PF, ESI, LWF, professional tax and TDS compliances and returns as per your requirement.
                        </p>
                      
                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Winding up of Company</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      
                      <p className="marginTop10">
                        GST Health is the accurate platform to easily access the various documents related to HR. It is very easy to have a glance on the number of documents namely:
                      </p>
                      <span className="headingH2 label primary">Employment Contract</span>
                      <p className="marginTop10">
                        It is one of the important written proofs of the professional relationship between an employer and an employee. GST Health provides you varieties of employment contracts according to your business requirement.
                        </p>
                        <span className="headingH2 label primary">Offer letter</span>
                      <p className="marginTop10">
                        GST Health provides samples of offer letters which give insights into what should be included in your Job offer letter such as job description, reporting structure, job title, acknowledgement of offer, salary, starting date etc.
                        </p>
                        <span className="headingH2 label primary">Performance Appraisal documents</span>
                      <p className="marginTop10">
                        Performance appraisal documents help in reviewing the growth and development of employees as well as the organization. Because it is necessary to have feedback exercise in an organization for smooth functioning of management. GST Health provides a Performance Improvement plan, performance warning letter, performance improvement letter etc.
                        </p>
                        <span className="headingH2 label primary">Employee Handbook</span>
                      <p className="marginTop10">
                        Employee handbook includes all the procedures, authorities and policies of the organization. It is an important HR document as it contains details of administrative procedures.
                        </p>
                        <span className="headingH2 label primary">Employee compensation documents</span>
                      <p className="marginTop10">
                        GST Health provides salary structure, bonus calculations, TDS calculations, Salary slips, ITR declaration etc. under employee compensation documents.
                        </p>
                        <span className="headingH2 label primary">Statutory documents</span>
                      <p className="marginTop10">
                        It is mandatory for a business to comply with the statutory requirements. It is important to maintain PF, ESIC, Payment of Gratuity act, professional tax, Statutory Forms, various rules for statutory deductions and exemptions etc.
                        </p>
                        <span className="headingH2 label primary">Employee management and recordkeeping</span>
                      <p className="marginTop10">
                        GST Health helps your organization for completing all documentation of employees. HR has to keep records and reports of the employee throughout their time in the organization. We provide Employee master file, Admin Manual, Stationery request template, warning Notice for Behaviour issues, performance issues, post probation increment certificate etc.
                        </p>
                        <span className="headingH2 label primary">Exit process</span>
                      <p className="marginTop10">
                        It is necessary to complete leaving formalities, exit interview, clearance form, full and final settlement in case an employee is leaving the company due to any reasons. GST Health provide all documents related to exit process also.
                        </p>                     

                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default HRManage
