import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const ImportExportCode = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">Import Export Code</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        
                        <p className="marginTop10">
                            Import Export Code referred to as a 10 digit identification number which is issued by the Director-General of Foreign Trade, Department of Commerce, Government of India. It is also known as the Importer Exporter Code. It’s impossible to deal with export or import business without this code. IE Code registration is permanent registration which is valid for life term. Hence, there’ll be no hassles for updating, filing, and renewal of IE Code registration. It is in continuance till the business exists or the registration is revoked or surrendered. Further, unlike tax registrations like GST registration or PF registration, the importer or exporter does not require to file any filings or follow the other compliance requirement like annual filing.
                        </p>

                        <span className="headingH2 label primary marginTop10">Benefits:</span>
                        
                        
                        <p className="marginTop10">
<ul>
<li>You can register for International online marketplaces like amazon.com, alibaba etc.</li>
                        <li>You can easily claim the refund of various taxes paid while exporting the goods.</li>
                        <li>In some cases, the government waives the import duties, if you promise to export the goods within a time frame.</li>
                        <li>Validity for lifetime.Business Expansion</li> 
                        <li>Various benefits from the DGFT</li>
                        <li>No return filing</li>
                        <li>Easy to process</li>
                        <li>Available for lifetime</li>
</ul>

                        </p>
                      
                        
                    </div>
                </div>
            </div>
      </div>
    )
}

export default ImportExportCode
