import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const WindingUp = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="windingup-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">Winding up of LLP</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">Winding up of Company</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="windingup-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">Winding up of LLP</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">
                        LLP or Limited Liability Partnership Act 2008 governs the procedure for winding up of the LLP. It is a new form of business entity and it enjoys audit exemption if the annual turnover is less than Rs. 40 lakhs and the capital contribution is less than 25 lakhs. There are two ways of winding up of LLP: first is voluntary winding up and second is winding up by tribunal.
                        </p>
                      <span className="headingH2 label primary">Reasons for winding up</span>
                      
                      <ul className="marginTop10">
                        <li>The LLP wants to be wound up.</li>
                        <li>If the partners are less than two in the LLP for a period of more than 6 months.</li>
                        <li>When LLP is not in position to pay its debt.</li>
                        <li>The LLP has not filed with the Registrar Statement of Accounts and Solvency or LLP Annual Returns for any five consecutive financial years.</li>
                        <li>When LLP is not in position to pay its debt.</li>
                      </ul>

                      <span className="headingH2 label primary">Procedure of Winding Up of LLP</span>
                     
                      <ul className="marginTop10">
                        <li>A resolution for winding up of LLP needs to be passed and filed with the registrar within 30 days.</li>
                        <li>When you pass the resolution of winding up of LLP, the voluntary winding up will  commence.</li>
                        <li> After resolution, the majority of partners shall make a declaration verified by an  Affidavit to the effect that LLP has no debt or that it will be in position to pay its debts in full within a specified period.</li>
                        <li> Along with that following documents must be filed within 15 days of passing of  the resolution :</li>
                        <li> Statement of assets and liabilities for the time period from last accounts closure to the date of winding up of LLP attested by at least two partners.</li>
                        <li >Report of valuation of assets Creditors are required to provide their opinion on winding up of LLP within 30 days of receipt of request for approval for winding up.</li>
                        <li>LLP liquidator must be appointed within thirty days of passing of resolution. It is the duty of an LLP liquidator to perform the functions and duties for winding up of LLP.</li>
                        <li>Resident not ordinarily resident and a Non-resident.</li>
                        <li>Those individuals who are not eligible and are not capable of filing using ITR-1, because of their income exceeding ₹ 50 Lakhs.</li>
                      </ul>
                     
                      
                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Winding up of Company</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      
                      <p className="marginTop10">

                        Winding up is the last stage of the process of dissolving a company.  There may be various reasons for winding up of a company like loss, bankruptcy, death of promoters, an agreement among stakeholders, etc. The sole purpose of winding up a company is to sell off stock, pay off creditors, and distribute any remaining assets to partners or shareholders.

                        <br/>As per section 270 of the companies act, 2013 company can be wound up either

                      </p>
                      <ul className="lodoc marginTop10">
                        <li>By tribunal or</li>
                        <li>By way of voluntary winding up.</li>
                      </ul>

                      <p className="marginTop10">
                        The process of winding up is subject to the supervision of the court. The whole process takes place under the supervision of a court where the court supervises the winding-up proceedings which are subject to certain conditions imposed by the court. The court provides the opportunity to stakeholders to file for winding up petitions even when the company is being wound up voluntarily.
                        <br/>
                        Here the petitioner needs to prove the voluntary winding up cannot be done with fairness to all concerned parties.
                      </p>
                      
                      <span className="headingH2 label primary">The process of voluntary Winding up of Company</span>
                      
                      <ul className="marginTop10">
                          <li>A board meeting to be organised with two directors or by a majority of directors. Here a resolution needs to be passed with a declaration by the directors that the company has no debts or it will be able to pay its debts in full from the proceeds of the assets sold in voluntary winding up of the company.</li>
                          <li>To issue notice in writing for the general meeting of the company proposing resolutions.</li>
                          <li>In a General meeting a resolution for winding up should be passed by ordinary majority or special resolution by ¾ majority.</li>
                          <li>Next step is to conduct the meeting of the creditors. If creditors agree by two third majority then the company can be wound up voluntarily. And if the creditor does not agree then the company will be wound up by tribunal.</li>
                          <li>To file a notice with the registrar for appointment of liquidator.</li>
                          <li>Within 14 days of resolution, give notice of the resolution in the Official Gazette and also advertise it in the newspaper.</li>
                          <li>Occupation</li>
                          <li>Then within the next 30 days of the general meeting for winding up of company file certified copies for winding up of company.</li>
                          <li>Wind up affairs of the company and prepare the liquidators account of the winding up and get the same audited.</li>
                          <li>Email address.</li>
                          <li>Organise for the final general meeting of the company.</li>
                          <li>File copy of accounts and file application to the tribunal for passing an order for dissolution of the company.</li>
                          <li>If the tribunal is satisfied, it may pass an order within 60 days of receiving the application.</li>
                          <li>The company liquidator would often file a copy of the order with the registrar.</li>
                          <li>The registrar on receiving the copy of order passed will publish a notice in the official Gazette that the company is dissolved.</li>

                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default WindingUp
