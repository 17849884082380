import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const PTax = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">Professional Tax</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        
                        <p className="marginTop10">
                           
                            Professional tax is a tax that is levied on all kinds of professions, trades, and employment on the basis of such income. It is imposed by the state government in India.
                            <br/>Each professional whether the Directors of the company , partners of LLP or any other employer is under the obligation to have professional tax registration and to ensure that it must be deducted from the salary of employees. And this deducted tax is deposited in the appropriate office designated by the state government and a return of the professional tax must be filed specifying the payment of professional tax.

                        </p>

                        <span className="headingH2 label primary marginTop10">Benefits of Professional Tax</span>
                        <ul className="marginTop10">
                            <li>Very easy to comply and follow the regulations of professional tax</li>
                            <li>Failure to pay this tax results in huge penalties</li>
                            <li>According to the rules and regulations of India, every employee is bound by the judiciary to obtain the registration of professional tax.</li>
                            <li>You can claim deductions in the salary on the basis of professional tax paid.</li>
                            <li>The local authorities and the state government have the right to collect professional taxes based on employment.</li>

                        </ul>
                        <span className="headingH2 label primary marginTop10">Registration Process</span>
                        <p className="marginTop10">
                            The registration process of professional tax varies from state to state.
                        </p>

                       
                        <span className="headingH2 label primary marginTop10">Frequency to file returns</span>
                        <p className="marginTop10">
                            It is important to know the rules of the state before filing for a return because it depends on the state the person lives in.
                        </p>

                        <span className="headingH2 label primary">Penalties</span>
                        <p className="marginTop10">
                            This tax is levied on all types of business entities. Delay in obtaining professional tax can lead to a penalty. In case of late payment of professional tax the penalty will be 10% of the amount of tax.
                        </p>
                      <span className="headingH2 label primary marginTop10">Professional Tax Rate</span>
                      <p className="marginTop10">
                        The maximum amount payable p.a. This tax is INR 2500. This tax is usually a slab amount based on the gross income of the professional.
                        </p>
                        <span className="headingH2 label primary marginTop10">Responsibility of Employer</span>
                      <p className="marginTop10">
                        The owner of a business is responsible for deducting professional tax from the salaries of his employees and paying the amount to the appropriate government department in the prescribed form within the specified time.
                        </p>
                        <span className="headingH2 label primary marginTop10">First day</span>
                      <p className="marginTop10">
                        Within the first working day, you need to provide your PAN details, address proof and identity proofs of all the directors/partners/proprietors of the company.
                        </p>
                        <span className="headingH2 label primary marginTop10">Second day</span>
                      <p className="marginTop10">
                        On the second working day the application form for professional tax will be filled by the employees and then submitted to the concerned authorities.
                        </p>
                        <span className="headingH2 label primary marginTop10">Seven days</span>
                      <p className="marginTop10">
                        Within seven working days the basic acknowledgment will be given to you. In case the documents are complete, then the registration hard copy will be issued within 10 days in all major cities. Otherwise you need to submit your documents if missing as mentioned by authorities.
                        </p>
                        <span className="headingH2 label primary marginTop10">Exemptions from Professional Tax</span>
                        <ul className="marginTop10">
                        <li>Members of the armed forces including members of auxiliary forces or reservists serving in the state</li>
                        <li>Parents of children with a permanent disability</li>
                        <li>Individuals over 65 years of age</li>
                        <li>Physically challenged individual</li>
                        <li>Women engaged as agent in Mahila Pradhan Kshetriya Bachat Yojana or Director of Small Savings</li>
                        <li>Badli workers in textile industry</li>
                        </ul>
                        
                    </div>
                </div>
            </div>
      </div>
    )
}

export default PTax
