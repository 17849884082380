import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const ITService = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="startBiz-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">Income Tax Return Filing</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">TDS Return Filing</a></li>
                    <li className="tabs-title"><a href="#panel3v" onClick={refreshPage} className="headingH3">Income Tax Notice</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="startBiz-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">Income Tax Return Filing</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">
                            A taxpayer needs to file his income tax return for reporting his income for a financial year, carrying forward losses, claiming an income tax refund, etc. According to the Income Tax Act, 1961, an income tax return is a form which enables a taxpayer to declare his income, expenses, tax deductions, investments, taxes etc., which makes it compulsory under many situations for a taxpayer to file an income tax return.
                            Income tax returns can be filed very easily through an online portal just sitting at home. A taxpayer needs to choose the right income tax from the following forms.
                        </p>
                      <span className="headingH3 display marginTop10">There are different types of income Tax return which are as follows:</span>
                      <span className="headingH2 label primary">ITR-1</span>
                      <p className="marginTop10">
                        ITR-1 Form is also known as Sahaj, it is the Income Tax Return Form for salaried individuals. ITR -1 Form is a simple one-page form for individuals having income up to Rs 50 lakh from the following sources:
                      </p>
                      <ul className="marginTop10">
                        <li>Income from Pension or Salary.</li>
                        <li>Income from  House Property (not including cases of loss from previous financial years).</li>
                        <li>Income from further Sources (not including winning Lottery and Race Horses earned income).</li>
                      </ul>

                      <span className="headingH2 label primary">ITR-2</span>
                      <p className="marginTop10">
                        The ITR 2 form is for Individuals and HUFs (Hindu Undivided Family) who has no income from business or profession. It is applicable for Individuals or HUFs who receive income other than “Profits and Gains from Business or Occupation”. This return form will be applicable if you receive income from the following sources:
                      </p>
                      <ul className="marginTop10">
                        <li>Income from Salary/Pension.</li>
                        <li>House property (It can be from one or more house property).</li>
                        <li>Income from capital gains or loss on the sale of property ( both short term and long term).</li>
                        <li>Income from other sources (like bets on horse races, winning of lotteries, and any legal methods of gambling).</li>
                        <li>Foreign Assets income.</li>
                        <li>Agricultural income which exceeds Rs. 5000.</li>
                        <li>The profit share of the partner from a partnership firm.</li>
                        <li>Resident not ordinarily resident and a Non-resident.</li>
                        <li>Those individuals who are not eligible and are not capable of filing using ITR-1, because of their income exceeding ₹ 50 Lakhs.</li>
                      </ul>
                     
                      <span className="headingH2 label primary">ITR-3</span>
                      <p className="marginTop10">
                        This form is generally used by an individual or a Hindu Undivided Family having income from a proprietary business or carrying on any profession. Eligibility for ITR 3:
                      </p>
                      <ul className="marginTop10">
                        <li>Carrying on a business or profession.</li>
                        <li>The return includes income from House property, Salary/Pension, and Income from other sources.</li>
                      </ul>

                      <span className="headingH2 label primary">ITR-4</span>
                      <p className="marginTop10">
                        This Form applies to individuals or HUFs having income from a proprietary business or carrying on any profession who have opted for the presumptive income scheme as per Section 44ADA and Section 44AE of the Income Tax Act. ITR-4 cannot be filed if:
                      </p>
                      <ul className="marginTop10">
                        <li>Businesses with turnover of over Rs. 2 Crores.</li>
                        <li>Professionals with gross receipts exceeding Rs. 50 Lakhs.</li>
                        <li>Companies are also on the exception list to file ITR-4.</li>
                      </ul>

                      <span className="headingH2 label primary">ITR-5</span>
                      <p className="marginTop10">
                        ITR-5 is filed by firms, LLPs (Limited Liability Partnerships), AOPs (Association of persons) and BOI's (Body of Individuals), artificial juridical person, cooperative society, and local authority.
                      </p>

                      <span className="headingH2 label primary">ITR-6</span>
                      <p className="marginTop10">
                        It is an income tax return form used by companies to e-file income tax returns if they do not claim an exemption under Section 11 of the Income Tax Act, 1961.
                      </p>

                      <span className="headingH2 label primary">ITR-7</span>
                      <p className="marginTop10">
                        This is filed in a situation where persons including companies come under section 139(4A) or section 139 (4B) or section 139 (4C) or section 139 4(D). No other document except the TDS certificate should be attached with this return form while filing ITR-7. It is advised to taxpayers for matching the taxes deducted/collected/paid by or on behalf of them with their Tax Credit Statement Form 26AS
                      </p>

                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">TDS Return Filing</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      
                      <p className="marginTop10">
                        TDS return filing is a mandatory source of collecting tax by the Government of India. The tax is deducted at the time when money is credited to the payee’s account or at the time of payment, whichever is earlier. We can easily file the return through an official Income Tax e-filing portal.
                      </p>
                      <p className="marginTop10">
                        Filing of TDS returns can be done online. After submitting TDS returns, the details will come upon the payee's Form 26AS.
                      </p>
                      
                      <p className="marginTop10">
                        Companies which are having valid Tax Deduction and Collection Account Number (TAN) can file for TDS returns. As mentioned under the Income Tax Act, TDS must be filed for the below-mentioned payments:
                      </p>

                      <ul className="marginTop10">
                        <li>Insurance commission.</li>
                        <li>Salary payment.</li>
                        <li>Any gains generated by winning puzzles, lotteries, and similar type of games.</li>
                        <li>Income on securities.</li>
                        <li>Income generated by winning horse races.</li>
                        <li>Payments made towards National Savings scheme.</li>
                      </ul>
                      <p className="marginTop10">
                         A person can check the TDS return status of the return can be checked by providing the provisional Receipt number and the PAN details on the official website of the NSDL.
                      </p>

                      <span className="headingH2 marginTop10">Process for TDS Return filing</span>
                      <ul className="marginTop10">
                          <li>Form 27A which contains multiple columns is to be filled completely. In case a   hard copy is filled then it must be verified with an e-TDS return that has been  electronically filed.</li>
                          <li>TDS and the total amount that has been paid must be correctly filled and must be tallied in their respective forms.</li>
                          <li>Please make sure that the correct TAN of the organization is mentioned on Form 27A. Otherwise, the process of verification becomes difficult.</li>
                          <li>TDS returns must have the information related to challan number, the mode of payment, and the tax details.</li>
                          <li>Now enter 7-digit BSR to make tally easy.</li>
                          <li>The next step is to submit TDS returns at the TIN-FC. When returns are filed online, they can be submitted on the official website of the NSDL TIN.</li>
                          <li>If information is correct, a token number or provisional receipt will be received. Acknowledgment received works as a confirmation that the TDS Returns have been filed.</li>
                          <li>If it is rejected, a non-acceptance memo along with the reasons for the rejection is issued. In such a situation, the TDS Returns needs to be filed again.</li>

                      </ul>

                    </div>

                    <div className="tabs-panel" id="panel3v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Income Tax Notice</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                    <p className="marginTop10">
                        When you are not filing an income tax return, there is a defect in the filing of tax return or due to any other reasons where the tax department requires some information but you are  not able to perform these major tasks on time then the income tax Department sent you the Income-tax notice.
                        <br/>But you don’t need to be surprised if you received this notice, GST Health tax experts will guide you to understand the details of the notice and advise you on how to comply with tax notice or order. Different types of Tax notices are as follows:
                    </p>
                      <span className="headingH2 label primary marginTop20">Intimation notice under Section 143(1)</span>
                      <p className="marginTop10">
                        This is the commonly received income tax notice of intimation. The income tax department sends this notice for providing a response to the errors/ incorrect claims/ inconsistencies in an income tax return that was filed. After receiving this notice, you can revise it within 15 days. Otherwise, the tax return will be processed after making necessary adjustments mentioned in the 143(1) tax notice.
                        </p>
                      
                      <span className="headingH2 label primary">Scrutiny Notice under Section 143(2)</span>
                      <p className="marginTop10">
                        In this notice, the taxpayer needs to respond to the questionnaire issued along with the documents required by the income tax department. Within 6 months after the completion of the assessment year to which it pertains, the assessing officer is supposed to serve this notice. The main purpose of this notice is to notify the assessee that your return has been picked for scrutiny.
                      </p>
                      <span className="headingH2 label primary">Inquiry Notice under section 142(1)</span>
                      <p className="marginTop10">
                        This notice is addressed to the assessee when the return is already filed and further details of documents are required from the assessee to complete the process. The purpose of this notice is to provide additional documents and information.
                      </p>
                      <span className="headingH2 label primary">Defective return Notice under 139(1)</span>
                      <p className="marginTop10">
                        This is issued if the income tax return filed does not contain all necessary information or incorrect information. If this notice is issued then you should rectify it within 15 days.
                      </p>
                      <span className="headingH2 label primary">Demand Notice under Section 156</span>
                      <p className="marginTop10">
                        This notice is issued when any tax, interest, fine or any other sum is owed by the taxpayer.
                      </p>
                      <span className="headingH2 label primary">Notice under Section 245</span>
                      <p className="marginTop10">
                        When the tax has not been paid for the previous years and the assessing officer wants to set off the current year refund against that demand, a notice under Section 245 can be issued.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default ITService
