import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const FssaiRegistration = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">FSSAI Registration</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                            The Food Safety and Standards Authority of India has been established under Food Safety and Standards, 2006 which consolidates various acts & orders that have handled food-related issues in various Ministries and Departments. FSSAI has an objective for laying down science-based standards for articles of food and to regulate their manufacture, storage, distribution, sale, and import to ensure availability of safe and wholesome food for human consumption. The objective of this Act is to establish a single reference point for all matters relating to food safety and standards, by moving from multi-level, multi-departmental control to a single line of command. The Act establishes an independent statutory Authority – the Food Safety and Standards Authority of India with head office in Delhi. Food Safety and Standards Authority of India (FSSAI) and the State Food Safety Authorities shall enforce various provisions of the Act.
                        </p>

                        <span className="headingH2 label primary marginTop10">Advantages</span>
                        <p className="marginTop10">
                            <span style={{fontWeight:"700"}}>1:</span> Legally certifies your food processing business.<br/>
                            <span style={{fontWeight:"700"}}>2:</span> Creates awareness across the country about food safety measures provided by your food company.<br/>
                            <span style={{fontWeight:"700"}}>3:</span> This registration helps you in getting good consumer base.<br/>
                        </p>

                        <p className="marginTop10">
                            FSSAI registration is required for all petty food business operators. Petty food business operator is any person or entity who:
                        </p>

                        <span className="headingH2 label primary">Who is involved in the process of share transfer?</span>
                      <ul className="marginTop10">
                          <li>Manufacturers or sells any article of food himself or a petty retailer, hawker, itinerant vendor, juice stall, sweets shops or temporary stall holder; o</li>
                          <li>Hawker sells packaged or freshly prepared food by travelling on a movable cart or foot from one location to another.</li>
                          <li>Small scale or cottage or such other industries relating to food business or tiny food businesses with an annual turnover not exceeding Rs 12 lakhs and whose:</li>
                              <ul>
                                  <li>Production capacity of food (other than milk and milk products and meat and meat products) does not exceed 100 kg/ltr per day or</li>
                                  <li>Procurement or handling and collection of milk is up to 500 litres of milk per day or</li>
                                  <li>Slaughtering capacity is 2 large animals or 10 small animals or 50 poultry birds per day or less.</li>
                              </ul>
                      </ul>
                      <p className="marginTop10">
                            Petty food business operators are required to obtain a FSSAI registration by submitting an application for registration in Form A. On submission of a FSSAI registration application, the registration should be provided or application rejected in writing within 7 days of receipt of an application by authority.
                            <br/>
                            <span className="headingH3 display marginTop10"> FSSAI License - State</span>
                            <br/>
                            Any person or entity that does not not classify as a petty food business operator is required to obtain a FSSAI license for operating a food business in India. FSSAI license is of two types, State FSSAI License and Central FSSAI License. Based on the size and nature of the business, the licensing authority would change. Some of the types of entities that require FSSAI state license are:

                        </p>
                        <ul className="marginTop10">
                          <li>Hotel providing lodging, meals and other guest services - upto four star rating.</li>
                          <li>Restaurants and bars having turnover of upto Rs.20 crores per year.</li>
                          <li>Club/Canteen.</li>
                          <li>Caterers having turnover of upto Rs.20 crores per year</li>
                          <li>Food vending agencies having upto 100 vending machines in only 1 state.</li>
                          <li>Mid day meal caterers having turnover of upto Rs.20 crores.</li>
                          <li>Mid day meal canteens having turnover of more than Rs.12 lakhs per year.</li>
                          <li>Food product marketers with a turnover of upto Rs.20 crores per year.</li>
                          <li>Food product retailers with turnover of upto Rs.20 crores per year.</li>
                          <li>Food product suppliers  with turnover of upto Rs.20 crores per year.</li>
                          <li>Food product distributors  with turnover of upto Rs.20 crores per year.</li>
                          <li>Food product wholesalers  with turnover of upto Rs.30 crores per year.</li>
                          <li>Food transporters having upto 100 vehicles and a turnover of upto Rs.30 crores per year.</li>
                          <li>Dairy units processing over 500 liters of milk per day or 2.5 MT of milk solids per year</li>
                        <li>Vegetable oil processing units with turnover of more than Rs.12 lakhs per year</li>
                        <li>Slaughterhouse processing more than 50 poultry birds or 11 small animals or 3 large animals</li>
                        <li>Meat processing unit with a capacity of more than 500kg of meat per day or 150 MT of meat per year</li>
                        <li>All grains, cereals, pulses milling units without any limit on production capacity</li>
                        <li>Food manufacturing or processing or packing unit with turnover of more than Rs.12 lakhs per year or production capacity of more than 100kg or 100 liters per day</li>
                        <li>Cold storage unit with turnover of more than Rs.12 lakhs per year</li>
                        <li>Cold + Atmospheric Controlled Storage with turnover of more than Rs.12 lakhs per year</li>
                        <li>Storage unit with turnover of more than Rs.12 lakhs per year</li>

                      </ul>

                        <p className="marginTop10">
                            <span className="headingH3 display marginTop10"> FSSAI License - Central</span>
                            <br/>
                            Large food manufacturers / processors / transporters and importers of food products require a central FSSAI license. Some of the types of entities that require FSSAI central license are:
                        </p>
                        <ul className="marginTop10">
                          <li>Five star hotels.</li>
                          <li>Restaurants with a turnover of more than Rs.20 crores per year.</li>
                          <li>Caterers with a turnover of more than Rs.20 crores per year</li>
                          <li>Food vending agencies with more than 100 vending machines or having machines in two or more states.</li>
                          <li>Food product importers.</li>
                          <li>Food product exporters</li>
                          <li>Selling food products through e-commerce</li>
                          <li>Registered office of food business operators having operations in two or more state.</li>
                          <li>Food business activity in premises belonging to Central Government Agencies.</li>
                          <li>Departmental Canteens at the premises belonging to Central Government Institutions.</li>
                          <li>Food business activity in Airport or Seaport.</li>
                          <li>Food business activity in Railway Stations.</li>
                          <li>Food product marketers with turnover of more than Rs.20 crores per year.</li>
                          <li>Food product retailers with turnover of more than Rs.20 crores per year.</li>
                          <li>Food product suppliers  with turnover of more than Rs.20 crores per year.</li>
                          <li>Food product distributors  with turnover of more than Rs.20 crores per year.</li>
                          <li>Food product wholesalers  with turnover of more than Rs.30 crores per year.</li>
                          <li>Food product transporters with turnover of more than Rs.30 crores per year and having more than 100 vehicles.</li>
                          <li>Proprietary or novel food manufacturers.</li>
                          <li>Mid-day meal caterers with a turnover of more than Rs.20 crores per year.</li>
                          <li>Food manufacturing or processing or packing unit with a production capacity of more than 2MT per day.</li>
                          <li>Dairy units with capacity to process more than 50,000 liters of milk per day or more than 2500 MT of milk solids per year.</li>
                          <li>Vegetable oil processing units with a processing capacity of more than 2MT per day.</li>
                          <li>Slaughtering house with a capacity to process over 1000 birds or 150 small animals or 50 large animals per day.</li>
                          <li>Meat processing units with processing capacity of more than 500kg of meat per day or 150MT of meat per annum.</li>
                          <li>Cold storage units with a capacity of more than 10,000 MT.</li>
                          <li>Cold storage that is atmospheric controlled with a capacity of more than 1000 MT.</li>
                          <li>Food storage with a capacity of more than 50,000 MT.</li>
                          <li>Every petty food business operator shall register themselves with the registering authority.</li>
                          <li>Unique application reference Number.</li>
                          <li>Now the registering authority may grant or reject registration in writing within 7 days.</li>
                          <li>If granted then you can start the business and if not granted then inspection is ordered within 7 days.</li>
                          <li>No registration fees are required for this process.</li>
                          <li>After the satisfactory result of inspection registration will be granted and FBO may start the business.</li>
                      </ul>

                    </div>
                </div>
            </div>
      </div>
    )
}

export default FssaiRegistration
