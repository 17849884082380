import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const DigitalSignature = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">Digital signature</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                            A Digital Signature Certificate (DSC) refers to a secure digital key that is issued by the certifying authorities for the purpose of validating and verifying the identity of the person. In Digital Signatures public key encryptions are used to create the signatures.Instead of signing hard copy documents physically and scanning them to send it time consuming but with DSC you can send them more quickly.Digitally signed signature cannot be altered or edited after signing so it makes the data safe and secure.Digitally signed documents provide assurance of the signer’s authenticity.
                        </p>
                        <span className="headingH2 label primary marginTop20">Procedure</span>
                        <p className="marginTop10">
                            <span style={{fontWeight:"700"}}>Step 1:</span> Contact to GST health.<br/>
                            <span style={{fontWeight:"700"}}>Step 2:</span> Email All Documents.<br/>
                            <span style={{fontWeight:"700"}}>Step 3:</span> DSC Ready.<br/>
                        </p>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default DigitalSignature
