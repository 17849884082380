import React from 'react'
import covervid from '../GST-HEALTH_VIDEO.mp4';

function aboutUs() {
    return (
        <div className="row">
            <div className="grid-x grid-padding-x alignLeft">
                <div className="large-4">
                    <video style={{width:"100%",padding:"2px", border:"5px solid #ffc10a",borderRadius:"5px"}}  loop autoPlay>
                        <source src= { covervid } type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                        <source src= { covervid } type="video/ogg" />Your browser does not support the video tag. I suggest you upgrade your browser.
                    </video>
                </div>
                <div className="large-8">  
                <p className="marginTop10">
                    Are you planning to start a business? But not able to identify from where to start! GST HEALTH is here to plan and execute your business ideas. Before starting,Let’s conduct a SWOT analysis of your business ideas to know the strength and weaknesses day to day.
                </p>
                <p className="marginTop10">
                    Starting a business is always a big task for a beginners as one may find it difficult to know where he should go and what steps he should take before and after starting the business. Also it is important to know what kind of business is suitable according to a person. GST HEALTH is here to solve your problems related to the selection and setup for your kind of business:
                </p>
                <p className="headingH2 marginTop20">
                    We are proudly introducing our venture with the Name as GST HEALTH.
                </p>
                <p className="marginTop20">
                    GST HEALTH is based out in Delhi NCR and it is a group of well experienced accountants and chartered accountants who is having experience of more than 20 years. We provide all kind of taxation support like GST, Income-tax,TDS,PAN,TAN,Digital signature,Accounting or bookkeeping .
                </p>
                <p className="marginTop10 marginBottom40">
                    Our experienced team is always ready to help our clients. We are available online & offline and ready to provide all the committed services within the timeline. Doing the taxation formality on time is a necessity which always helps any organisation or any individual saving from late payment fee and interest too .
                </p>
                
                </div>
            </div>
        </div>
    )
}

export default aboutUs
