
import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const additionalCompliance = () => {
    return (
        <div className="row">

            <div className="grid-container tabsContentContainer">
              <div className="grid-x">
                <div className="cell medium-3">
                  <ul className="vertical tabs"  data-responsive-accordion-tabs="tabs medium-accordion large-tabs" data-allow-all-closed="true" data-deep-link="true" data-deep-link-smudge="true"  id="startBiz-tabs">
                    <li className="tabs-title is-active"><a href="#panel1v" onClick={refreshPage} className="headingH3" aria-selected="true">Registered Office Change</a></li>
                    <li className="tabs-title"><a href="#panel2v" onClick={refreshPage} className="headingH3">Add Directors</a></li>
                    <li className="tabs-title"><a href="#panel3v" onClick={refreshPage} className="headingH3">Increased Authorised Capital</a></li>
                    <li className="tabs-title"><a href="#panel4v" onClick={refreshPage} className="headingH3">Share Transfer</a></li>
                    <li className="tabs-title"><a href="#panel5v" onClick={refreshPage} className="headingH3">MOA Amendment</a></li>
                    <li className="tabs-title"><a href="#panel6v" onClick={refreshPage} className="headingH3">Resignation of Director</a></li>
                  </ul>
                </div>
                <div className="cell medium-9">
                  <div className="tabs-content vertical" data-tabs-content="startBiz-tabs">
                    <div className="tabs-panel is-active" id="panel1v">
                      <p className="tabsContentHeading headingH1 borderOrange70 height70">Registered Office Change</p>
                      <span className="headingBorderBottom spanGetTouchTxt"></span>
                      <p className="marginTop10">The registered office address means a place to which all official communications related to the company are delivered. The company may have its different branch offices or corporate offices but while registering with the Ministry of Corporate Affairs only the registered office of the company is valid. Through the registration office of the company, the state of incorporation is also determined. The state or location in which the registered office of the Company is situated will determine the Registrar of Company (ROC) to which the application for company registration must be made. </p>
                      
                      <p className="marginTop10">If any change of address is required then it must be notified to the ROC within the time period of 15 days. It includes a change of registered office in 4 ways: </p>
                      
                      <p className="marginTop10">
                            <span style={{fontWeight:"700"}}>- Change within local limits of City</span> <br/>
                            <span style={{fontWeight:"700"}}>- One city to another within same ROC</span> <br/>
                            <span style={{fontWeight:"700"}}>- One Person Company (OPC)</span> <br/>
                            <span style={{fontWeight:"700"}}>- From Jurisdiction of One ROC to Another</span> <br/>
                            <span style={{fontWeight:"700"}}>- From one state to another state.</span> <br/>
                        </p>
                      
                        <span className="headingH2 label primary marginTop10">Registration Process</span>
                      <span className="headingH3 display marginTop10"> Identify New Address</span>
                        <p className="marginTop10">
                            The first step is to identify the new address for the registered address of the company in accordance with the Local Land Rules.
                        </p>
                        <span className="headingH3 display marginTop10">Rent lease agreement or Sale Deed.</span>
                        <p className="marginTop10">
                            The new place of address should be occupied by the company after a proper document is executed i.e. rent agreement or sale deed.
                        </p>
                        <span className="headingH3 display marginTop10">NOC</span>
                      <p className="marginTop10">It is necessary that if the new address is not owned then NOC from the owner of the premises should be attached.</p>
                      <span className="headingH3 display marginTop10">Meeting of directors</span>
                      <p className="marginTop10">A board meeting is necessary before changing address and resolution must be passed for change of the registered address with a simple majority.</p>     
                      <span className="headingH3 display marginTop10">File Form INC- 22</span>
                      <p className="marginTop10">Now Form INC- 22 is filed with the MCA within 30 days of the date of effective change of registered address.</p>
                     
                    </div>
                    <div className="tabs-panel" id="panel2v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Add Directors</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                    <p className="marginTop10">Every company needs a director of a company that is capable of managing the operations of the company. The director of a company is a person elected by the shareholders for managing the affairs of the company as per MOA and AOA.  The appointment of directors can be done on time to time-based on the requirements.
                            <br/> To become a director a person must obtain Digital Signature Certificate (DSC) and director identification number (DIN).  In the case of DIN, the nationality of a person doesn’t matter.
                        </p>
                      <span className="headingH2 label primary marginTop10">Types of Directors </span>
                      <span className="headingH3 display marginTop10"> Managing Director</span>
                      <p className="marginTop10">It means a Director who, by virtue of Articles of Association of a Company is entrusted with substantial powers of management of affairs of the company. </p>
                      <span className="headingH3 display marginTop10"> Executive Director</span>
                      <p className="marginTop10">An Executive Director or whole-time Director is a full-time employee of the company.</p>
                      <span className="headingH3 display marginTop10"> Additional Director</span>
                      <p className="marginTop10">Board of directors appoints an additional director between two annual general meetings according to the provisions of the Articles of Association of a Company. </p>
                      <span className="headingH3 display marginTop10"> Ordinary Director</span>
                      <p className="marginTop10">An “Ordinary Director” means a Director who attends the Board meetings of a company and participates in the matters put before the Board of Directors. </p>
                      <span className="headingH3 display marginTop10"> Alternate Director</span>
                      <p className="marginTop10">Board of Directors appoints an alternate director in a general meeting to act for a Director called the “original director” during his absence for a period of not less than three months from India. </p>
                      <span className="headingH3 display marginTop10"> Women director in Company</span>
                      <p className="marginTop10">It is mandatory for the listed companies and limited companies to appoint atleast one women director if paid up share capital of Rs 100 crore rupees or more or turnover of Rs. 300 crores or more.
                             <br/> Within seven working days the basic acknowledgment will be given to you. In case the documents are complete, then the registration hard copy will be issued within 10 days in all major cities. Otherwise you need to submit your documents if missing as mentioned by authorities.
                             <br/>Minimum number of directors in company which are as follows:-
                        </p>
                        <p className="marginTop10">
                            <span style={{fontWeight:"700"}}> - In case of Private Limited Company minimum two directors are required.</span> <br/>
                            <span style={{fontWeight:"700"}}>- In a limited company,there are a minimum of three directors</span> <br/>
                            <span style={{fontWeight:"700"}}>- In one Person Company minimum one director is necessary.</span> <br/>
                        </p>
                    </div>

                    <div className="tabs-panel" id="panel3v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Increased Authorised Capital</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                    <p className="marginTop10">
                        A company may increase its authorised share capital before issuing new equity shares and increasing paid-up capital. The share capital is that important part of the company’s equity which is raised by issuing shares and selling them to stockholders in exchange for capital.
                        </p>
                      <span className="headingH2 label primary marginTop10">What is Authorised share capital?</span>
                      <p className="marginTop10">
                        Authorised share capital is referred to as the total value of shares a company can issue.
                        </p>
                        <span className="headingH2 label primary marginTop10">What is paid-up capital?</span>
                      <p className="marginTop10">
                            Paid-up capital refers to the total value of shares the company has issued. Paid-up capital can never exceed authorised capital.
                            <br/> Sometimes it may happen that a company needs to increase authorized capital in order to issue new shares for more capital. It is mandatory to mention the provisions of increased authorised capital in AOA before applying for increasing authorized capital by passing a special resolution. Once the resolution is passed in the meeting, Form SH-7 must be filed by the company within 30 days of the passing of ordinary resolution. The necessary documents to be filed along with Form SH-7 are as follows:
                        </p>
                      <ul className="marginTop10">
                          <li>Notice related to EGM</li>
                          <li>Authorized true copy of ordinary resolution</li>
                          <li>Changed Memorandum of Association and Articles of Association</li>
                      </ul>
                        <p className="marginTop10">
                             According to the Companies Act and Companies Rules if the procedures for increasing authorised capital are followed then the Registrar would approve the filing and increase the authorised share capital of the company.
                        </p>
                        <span className="headingH2 label primary marginTop10">Process to Increase Authorised Capital</span>
                        <ul className="lodoc marginTop10">
                            <li>First step is to verify that AOA has the provision for increased authorised capital.</li>
                            <li>Call for a board meeting to decide time, date, place and agenda of the Extraordinary  General Meeting (EGM). Notification for the same is given to all members.</li>
                            <li>The Ordinary resolution to increase authorised capital is passed in the EGM by the approval of the members of the EGM. Then the change is to be made in MOA and AOA.</li>
                            <li>Name, address and the GSTIN of the recipient</li>
                            <li>The form is filed with ROC within 30 days from the EGM to increase authorised capital along with required documents as mentioned above.</li>
                            <li>When ROC checks and verifies all the documents, then approval to increase authorized capital will be granted.</li>
                        </ul>
                      
                    </div>

                    <div className="tabs-panel" id="panel4v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Share Transfer</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                      
            
                      <span className="headingH2 label primary">Share Transfer in Company</span>
                      <p className="marginTop10">
                          It is the procedure of transferring existing shares from one person to another either by sale or gift. Shares in a company are transferable like any other movable property; it is a voluntary handing over the rights to a person who wishes to become a member in a company.

                      </p>
                      <span className="headingH2 label primary">Who is involved in the process of share transfer?</span>
                      <ul className="marginTop10">
                          <li>Subscribers to the memorandum</li>
                          <li>Transferor</li>
                          <li>Transferee</li>
                          <li>Legal Representative (in case of deceased)</li>
                          <li>Listed/Unlisted Company</li>
                      </ul>
                      
                      <span className="headingH2 label primary">Share transfer procedure as per the Companies Act, 2013</span>
                      
                      <ul className="lodoc marginTop10">
                        <li>A transfer deed needs to be obtained in the prescribed form namely Form SH-4 endorsed by the prescribed authority.</li>
                        <li>
                              The instrument of transfer may not be in the prescribed form (Form SH-4) in the following situations:-
                             <br/><b>a.</b>  Section 187 of the Companies Act, 2013, where a director or nominee transfers shares on behalf of another body corporate;
                             <br/><b> b.</b>  Where transfer of shares takes place by director or nominee on behalf of a corporation owned or controlled by the central or state Government;
                             <br/><b>c.</b>  Transferring of shares by way of deposit as a security for repayment of any loan or advance If they are made with any of the following:-

                             <br/><b>i.</b>  State Bank of India; or
                             <br/><b>ii.</b>  Any scheduled bank; or
                             <br/><b>iii.</b>  Any other banking company; or
                             <br/><b>iv.</b>  Financial Institution; or
                             <br/><b>v. </b> Central Government; or
                             <br/><b>vi. </b> State Government; or
                             <br/><b>vii. </b> Corporation held by the Central or State Government; or
                             <br/><b>viii. </b> Trustees who have filed the declarations.

                             <br/><b>d.</b>  A standard format can be used as the instrument of transfer for transferring of shares. To get the AOA in case of shares, trust deed in case of debentures and transfer deed registered either by the transferor and the transferee or on their behalf according to the Companies Act, 2013.
                             <li>As per the Indian Stamp Act and stamp duty notification, the transfer deed needs to have stamps.</li>
                             <li>It is important to check the stamp affixed on the transfer deed is cancelled at the time of or before the signing of the transfer deed.</li>
                             <li>Where approval for transfer takes place the person who is giving his signature, name and address must see the transferor and the transferee sign the share/debentures transfer deed in person.</li>
                             <li>The transfer deed must be attached and sent to the company with relevant share/debenture certificate or allotment letter in it.</li>
                             <li> If the application by transferor is for partly paid shares, the company has to duly notify the amount due on shares/debentures to the transferee. No objection from the transferee is mandatory within two weeks from the date of receipt of the said notice.</li>
                             <li>The same value stamp on a written application must be affixed if the signed transfer deed is not available or lost.</li>
                             <li>The company cannot charge any fee for registration of transfers of shares and debentures If the shares of the company are listed in a recognized stock exchange.</li>
                             
                        </li>
                      </ul>
                      <span className="headingH2 label primary">Penalties</span>
                      <p className="marginTop10">
                        For company minimum is Rs. 25000 and maximum is Rs. 5, 00,000.
                      </p>
                    </div>
                    <div className="tabs-panel" id="panel5v">
                      
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">MOA Amendment</p>
                    <span className="headingBorderBottom spanGetTouchTxt"></span>
                    <span className="headingH2 label primary marginTop10">What is the Memorandum of Association of Company?</span>
                      <p className="marginTop10">
                              It is referred to as the constitution or charter of a company, a memorandum is an essential document for the incorporation of a company.it is a document which is formulated by the founder and signed by the founder members on the registration and establishment of a company.
                          <br/>Changes to memorandum of association can be done through special resolution at the shareholders meeting. It is a complex procedure therefore professional care must be taken during the procedure.
                        </p>
                        <span className="headingH2 label primary">Alternation of Name in MOA</span>
                        <p className="marginTop10">
                          The application of the name change along with the special resolution is filed with the MCA. Upon the approval from the MCA, a new certificate of incorporation is issued. The whole process of name change takes approximately 90 to 100 working days.
                        </p>
                        <span className="headingH2 label primary">Change of Registered office</span>
                        <p className="marginTop10">
                          As the company grows, it might want to shift its registered office to some other location due to the greater scope. A company can perform this function by notifying and getting approval from MCA and ROC. The procedure depends on certain situations:
                        </p>
                      <ul className="marginTop10">
                        <li>If the register is being shifted from one address to another within the limits of the same city or jurisdiction of the ROC.</li >
                        <li>If the registered office is being shifted from one city to another within the same state under a different ROC.</li>
                        <li>One of the notable conditions is when the registered office is being shifted from one state to another.</li>
                        <li>Import of goods and services will be included in IGST, with addition to applicable custom duties.</li>
                      </ul>

                      <span className="headingH2 label primary marginTop10">Alteration of Objects clause</span>
                      <p className="marginTop10">Here you need to mention the change of the objective and further necessary matters of the company.</p>
                      
                      <span className="headingH2 label primary marginTop10"> Alteration in Capital clause</span>
                      <p className="marginTop10">Change in the authorized share capital of the company can be done by passing of an ordinary resolution in a general meeting. Alteration of capital may relate to sub division of the shares, consolidation of the shares and conversion of shares into stock and annulment of unsubscribed capital.</p>
                      <span className="headingH2 label primary marginTop10">Alteration in Liability clause</span>
                      <p className="marginTop10">This clause can be changed to make the liability of the directors unlimited. It can be modified by passing a special resolution. A copy of the resolution should be filed with the registrar within a period of 30 days.</p>
                      <span className="headingH2 label primary marginTop10">Subscription of memorandum</span>
                      <p className="marginTop10">Subscribing is the process of appending one’s signature or mark to a document, for the purpose of approval or attestation of its contents. So it is necessary for founding members to subscribe their names to the memorandum.</p>
                      <span className="headingH2 label primary">What are the particulars to be mentioned in MOA?</span>
                      <ul className="lodoc marginTop10">
                        <li>Name (including surname and family name), and recent photograph affixed and scanned with MOA and AOA.</li>
                        <li>Name of the father/mother.</li>
                        <li>Nationality</li>
                        <li>Date of Birth</li>
                        <li>Place of Birth.</li>
                        <li>Educational qualification.</li>
                        <li>Occupation.</li>
                        <li>Income-tax permanent account number.</li>
                        <li>Permanent and current address.</li>
                        <li>Email address.</li>
                        <li>Contact number.</li>
                        <li>Fax number (optional).</li>
                        <li>Two ID proofs, out of which PAN card is mandatory.</li>
                        <li>Any residential proof, not older than two months.</li>
                        <li>Proof of nationality, if the subscriber is a foreign national.</li>
                        <li> If the subscriber is a current director or promoter of a company, particulars such as designation (whether a director or a promoter), along with the name and corporate identity number of the firm.</li>
                        
                      </ul>
                    </div>
                    <div className="tabs-panel" id="panel6v">
                    <p className="tabsContentHeading headingH1 borderOrange70 height70">Resignation of Director</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                           According to the provisions of the Company Act, 2013 it is mandatory for every company to have a minimum number of directors as specified. But if there is removal of any director or resignation given by any director, the company needs to notify and file it with the Ministry of Corporate Affairs.
                        </p>
                        
                        <span className="headingH2 label primary marginTop10"> There can be three situations where a company needs to file the removal of director with MCA</span>
                      <p className="marginTop10">Change in the authorized share capital of the company can be done by passing of an ordinary resolution in a general meeting. Alteration of capital may relate to sub division of the shares, consolidation of the shares and conversion of shares into stock and annulment of unsubscribed capital.</p>
                      <span className="headingH2 label primary marginTop10">Resignation given by Director</span>
                      <p className="marginTop10">When resignation is given by the director, in such cases the Board of Directors decides whether to accept or decline the resignation. If resignation is accepted then the Board passes resolution and files the removal of director with the MCA.</p>
                      <span className="headingH2 label primary marginTop10">Director removed by the Company</span>
                      <p className="marginTop10">In this case a meeting is organised with the purpose of passing an ordinary resolution in favour of removing the director. Also the concerned director is given an opportunity to be heard before passing the resolution.</p>
                      <span className="headingH2 label primary">Director doesn’t attend Board meetings</span>
                      <p className="marginTop10">When the director of the company abstains himself from attending three consecutive board meetings which mean the director is absent for 12 months even after receiving the notice. In such cases, the company files the removal of the director with MCA.</p>
                      <span className="headingH2 label primary">Process of Removal of Director</span>
                      <p className="marginTop10">It is necessary to be compliant with the MCA guidelines for the removal of a director.</p>

                      <span className="headingH2 label primary">Right to be Heard</span>
                      <p className="marginTop10">The first step is giving an opportunity to directors to be heard. The process of removal cannot be initiated without giving the proper opportunity to a director who is to be removed.</p>
                      <span className="headingH2 label primary">To Issue notice</span>
                      <p className="marginTop10">The second step is to issue notice. This notice is processed by shareholders holding a minimum voting power of 1% or who hold shares on which an aggregate sum of not more than Rs 5,00,000 has been paid upon the date of the notice. This type of notice is called a special notice that must be signed by all the members. This notice must be delivered at least 14 days prior to the date of the meeting to the company, at which the resolution will be passed.</p>
                      
                      <span className="headingH2 label primary">Notice to members</span>
                      
                      <p className="marginTop10">A copy of the notice must be sent to the director concerned before 7 days of the meeting. And notice must be mandatorily published on the company’s website.</p>
                      <span className="headingH2 label primary">Representation in writing</span>
                      
                      <p className="marginTop10">The complete description in writing can be made by the concerned director to the company against the notice of removal. Here the director has a right to make a plea to the company to send the written representation to all the members.</p>
                      <span className="headingH2 label primary">Appeal to the Tribunal</span>
                      
                      <p className="marginTop10">After completing the above steps, the organization or any aggrieved person after sending out representation can make an application to the Tribunal, requesting for nullification of the process.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
}

export default additionalCompliance
