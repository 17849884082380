import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const TradeLicence = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">Trade License</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        
                        <p className="marginTop10">
                           
                            Trade License is defined as a certificate/document which provides permission to perform on a specific trade or business for which it’s issued. It doesn't confer ownership of property or permission for any other activity other than for which it is issued. Some authorized departments by the municipal corporations are known as License departments which issue licenses by consulting with other specialized consultative departments like Fire Brigade, Health, Engineering, Estate, and others. According to this, it’s important for any business establishments to take fundamental licenses from the License Department before manufacturing, exchange or storage of any activity/commodity. It is necessary that the business establishment must receive the application within 3 months before the commencement of the activity.helps in ensuring that no one should be affected by health hazards of any trade and business.
                             It prohibits unethical business practices.Trade creates a balance in the society that every business is following rules and guidelines as prescribed by the Government. The government made this license mandatory to regulate the trade activities in the country.

                        </p>

                        <span className="headingH2 label primary marginTop10">Three different types of trade license</span>
                        
                        <span className="headingH3 display marginTop10">Shop License</span>
                        <p className="marginTop10">
                            Shop license is needed for those who would like to open a shop or an establishment to sell the products.
                        </p>

                       
                        <span className="headingH3 display marginTop10">Industrial License</span>
                        <p className="marginTop10">
                            Industrial license is required for a company who is planning to open a small or mid-sized industrial unit.
                        </p>

                        <span className="headingH3 display marginTop10">Food Establishment License</span>
                        <p className="marginTop10">
                            Any establishment that plans for the food and beverage industry needs this license.This license applies to restaurants, cafes, meat shops, bakeries and vegetable shops.
                        </p>
                      
                        
                    </div>
                </div>
            </div>
      </div>
    )
}

export default TradeLicence
