import Header from "./components/header";
import Footer from "./components/footer";
import StartBusiness from "./components/StartBusiness";
import GstService from "./components/GstService";
import ITService from "./components/ITService";
import BookAccountingService from "./components/BookAccountingService";
import DigitalSignature from "./components/DigitalSignature";
import Home from "./components/home";
import WindingUp from "./components/WindingUp";
import regularCompliance from "./components/regularCompliance";
import NoMatch from "./components/404";
import additionalCompliance from "./components/additionalCompliance";
import HRManage from "./components/HRManage";
import TradeLicence from "./components/TradeLicence";
import ImportExportCode from "./components/ImportExportCode";
import EsciRegistration from "./components/EsciRegistration";
import FssaiRegistration from "./components/FssaiRegistration";
import MsmeRegistration from "./components/MsmeRegistration";
import ContactUs from "./components/contactUs";
import PFAndForms from "./components/PFAndForms";
import tradeReg from "./components/tradeReg";

import './App.css';
import { Route , Switch } from "react-router-dom";
import PTax from "./components/PTax";
import aboutUs from "./components/aboutUs";

function App() {
  
  return (
    <div className="App">
      <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            
            
            
            <Route path="/StartBusiness" component={StartBusiness} />
            <Route path="/GstService" component={GstService} />
            <Route path="/ITService" component={ITService} />
            <Route path="/DigitalSignature" component={DigitalSignature} />
            <Route path="/BookAccountingService" component={BookAccountingService} />
            <Route path="/WindingUp" component={WindingUp} />
            <Route path="/regularCompliance" component={regularCompliance} />
            <Route path="/additionalCompliance" component={additionalCompliance} />
            <Route path="/HRManage" component={HRManage} />
            <Route path="/FssaiRegistration" component={FssaiRegistration} />
            <Route path="/MsmeRegistration" component={MsmeRegistration} />
            <Route path="/EsciRegistration" component={EsciRegistration} />
            <Route path="/ImportExportCode" component={ImportExportCode} />
            <Route path="/TradeLicence" component={TradeLicence} />
            <Route path="/PTax" component={PTax} />
            <Route path="/aboutUs" component={aboutUs} />
            <Route path="/contactUs" component={ContactUs} />
            <Route path="/PFAndForms" component={PFAndForms} />
            <Route path="/tradeReg" component={tradeReg} />
            <Route path='*' component={NoMatch} />
            
        </Switch>
      <Footer />
  </div>  
  )};

export default App;
