import React from 'react';
import emailjs from 'emailjs-com';
import '../App.css';
//import Axios from 'axios';

function refreshPage() {
    window.location.reload(false);
  }

function ContactUs() {
    const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState(0);
  const [query, setQuery] = React.useState("");
  
  
  
//   const displayInfo = () => {
//     console.log(name + email + mobile + query);
//   }
  
  const submit = (e) => {
      
      if (name === "") {
        alert("Please fill in all your concerns correctly !!");
      } else {
      emailjs.send("service_pu31x5w","template_mfohuyp",{
            name: name,
            email: email,
            mobile: mobile,
            message: query,
        },"user_0wg4k6RlcSwXwu1KLbuaR")
        .then((result) => {
            console.log(result.text);
            alert("Your query has been successfully submitted. Will see you soon!");
            refreshPage();  
        }, (error) => {
            console.log(error.text);
            alert("Your query has been failed. Please check back again!");
            refreshPage(); 
        });
    }
        
    };
  
//   const submit = (e) => {
//     Axios.post("http://localhost:3002/api/create", {
//       name: name,
//       email: email,
//       mobile: mobile,
//       query: query,
  
//     }).then(() => {
//       console.log("successful insert ->"+name+" "+email+" "+mobile+" "+query+" "+displayInfo);
//       alert("Your query has been successfully submitted. Will see you soon!");
//       refreshPage();     
//     })
//   };
    return (
        <div id="contact">
            <p className="tabsContentHeading headingH1 borderOrange70 height70 marginTop20">Contact Us</p>
            <span className="headingBorderBottom spanGetTouchTxt"></span>
              
            <div className="row">
                <div className="grid-x grid-padding-x">
                    <div className="large-6">
                        <span className="headingH2 label primary marginTop20 marginBottom20" style={{padding:"10px"}}>Our Office</span>
                        <p className="marginTop10 addressTxt">
                                069, Kaveri Tower, Mahagunpuram, NH-24, Mehrauli, Ghaziabad
                        </p>
                        <p className="marginTop10 addressTxt">
                            Email: <a href="mailto:info@gst-health.com">info@gst-health.com</a> <br/>
                            Call: +91-9990955853, +91-9718142553
                        </p>
                        <div className="bshadow marginBottom40">
                            <iframe name="firmAddress" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.7653311627296!2d77.47567481456042!3d28.666744389283632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf38242aa5913%3A0x7610e0cc1e115c36!2sKaveri%20Tower!5e0!3m2!1sen!2sin!4v1631567313094!5m2!1sen!2sin" width="100%" height="450" style={{border:"0",padding:"5px"}} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className="large-6">
                        <span className="headingH2 label primary marginTop20 marginBottom20" style={{padding:"10px"}}>Get A Free Quote</span>
                        <p className="marginTop10 addressTxt">
                            Please fill this form and we'll get back to you as soon as possible!
                        </p>
                       <div className="row">
                        <form style={{marginLeft: "1.5rem",width:"92%"}}>
                            <div className = "FormCon hide-for-small-only">
                            
                            <div className="grid-x grid-padding-x">
                                <div className="large-2 cell">
                                    <label htmlFor="name" className="text-right middle query1Form marginTop20"><b>Name</b>
                                    </label>
                                </div>
                                <div className="large-9 cell">
                                    <input type="text" id="name" className="marginTop20" placeholder="Please Enter your Name" required='required' onChange={(event)=>{ setName(event.target.value); }}/>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                            <div className="large-2 cell">
                                <label htmlFor="email" className="text-right middle query1Form marginTop10"><b>Email</b>
                                </label>
                            </div>
                            <div className="large-9 cell">
                                <input type="email" id="email" className="marginTop10" placeholder="Please Enter your Email" required='required' onChange={(event)=>{ setEmail(event.target.value); }}/></div>
                            </div>
                            <div className="grid-x grid-padding-x">
                            <div className="large-2 cell">
                                <label htmlFor="mobile" className="text-right middle query1Form marginTop10"><b>Mobile</b>
                                </label>
                            </div>
                            <div className="large-9 cell">
                                <input type="tel" id="mobile" className="marginTop10" placeholder="Please Enter your Point Of Contact" required='required' onChange={(event)=>{ setMobile(event.target.value); }}/>
                            </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="large-11 cell">
                                    <label htmlFor="query" className="text-right middle query1Form marginTop20"><b>Got queries? Write your message below</b></label>
                                    <textarea id="query" style={{marginLeft:"10px",height:"100px"}} placeholder="Please write your Concern..." required='required' onChange={(event)=>{ setQuery(event.target.value); }}></textarea>
                                </div>
                            </div>
                            <button type="button" className ="button marginTop20 marginBottom20" id="save" onClick={submit}>&nbsp;SUBMIT&nbsp;</button>
                        </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
