import React from 'react'

function tradeReg() {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70 marginTop10">Trademark Registration</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                    </div>

                    <div className="cell large-3 small-12">
                        <img src="images/trademark_pg.jpeg" className="inner" alt="trademark_registration" />
                    </div>

                    <div className="cell large-9 small-12">
                        <p className="marginTop10">
                            Trademark is one of the important types of intellectual property. A trademark can be defined as a sign which is used to distinguish the goods or services provided by one person or entity from those offered by another. A trademark may include words, designs, letters, numerals, slogans, devices, symbols, etc. Hence it is essential to give protection to words, designs, logos, numerals, and slogans. GST Health professionals will help to obtain your trademark registration in a hassle-free manner.
                        </p>

                        <p className="marginTop10">
                            Protection from competitors even in case there is a similar mark to the registered mark.Exclusive right to use trademark ownership nationwide and for all the products that are listed in the registration. Owners need to show proof of use at regular intervals.Failure to show proof on time related to trademark, lead to the loss of trademark registration rights.Sometimes it is considered that trademark is the weakest form of intellectual property because it only protects the marketing concept and not the product itself.
                        </p>
                        <p className="marginTop10">
                            The registration symbol (where R is written and is covered in a circle) can be used after the trademark is registered successfully.
                        </p>
                        <p className="marginTop10">
                            Trademark renewal is the benefit given to the registered trademark. A registered trademark is valid for 10 years and after that, it is important to renew it if you want to continue enjoying the benefits of a registered trademark. The Registrar sends a notice before 6 months of the expiration of the registered trademark, and if you do not respond to the notice then your registered trademark will be removed from Journal and stands expired.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default tradeReg
