import React from 'react'
import { NavLink } from 'react-router-dom';
import '../App.css';
//import Axios from 'axios';

export default function Header() {

  // const [name, setName] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [mobile, setMobile] = React.useState(0);
  // const [query, setQuery] = React.useState("");
  
  
  
  // const displayInfo = () => {
  //   console.log(name + email + mobile + query);
  // }
  
  // const submit = () => {
  //   Axios.post("http://localhost:3002/api/create", {
  //     name: name,
  //     email: email,
  //     mobile: mobile,
  //     query: query,
  
  //   }).then(() => {
  //     console.log("successful insert ->"+name+" "+email+" "+mobile+" "+query+" "+displayInfo);
  //     alert("Your query has been successfully submitted. Will see you soon!");
  //   })
  // };

    return (
        <div>
            <div className="row show-for-small-only">
          <div className="small-12 centered">
            <a href="/"><img src="images/gst_logo.png" className="logoHolder" alt="gst-health-logo" style={{height: "100px",margin: "5px"}}/></a>
          </div>
          <div className="row socialConMobile padding11">
            <span className="display"><a href="tel:+91-9990955853" rel="noreferrer"><img src="images/phone.png" className="marginTop10" alt="call_icon" /></a></span>
            <span className="display"><a href="https://wa.me/+919990955853" target="_blank" rel="noreferrer"><img src="images/whatsapp.png" className="marginTop10" alt="whatsapp_icon" /></a></span>
            <span className="display"><a href="mailto:info@gst-health.com" rel="noreferrer"><img src="images/email.png" className="marginTop10" alt="email_icon" /></a></span>
            <span className="display"><a href="https://www.facebook.com/gsthealth" rel="noreferrer" target="_blank"><img src="images/facebook_icon.png" className="marginTop10" alt="facebook_icon" /></a></span>
            <span className="display"><a href="https://www.instagram.com/gsthealth/" rel="noreferrer" target="_blank"><img src="images/instagram_icon.png" className="marginTop10" alt="instagram_icon" /></a></span>
        </div>
      </div>

      <div className="row hide-for-small-only">
          <div className="grid-x grid-padding-x alignLeft">
            <div className="large-3">
             <a href="/"><img src="images/gst_logo.png" alt="gst-health-logo" className="logoHolder" style={{height: "80px",margin: "5px 5px 10px 25px"}}/></a>
            </div>
            <div className="large-5">


            </div>
            <div className="large-4" style={{textAlign:"end"}}>
              <span data-tooltip className="top" style={{borderBottom: "none"}} tabIndex="2" title="  Call Us Now       :            +91-9990955853"><img src="images/phone.png" className="" style={{width:"95%"}} alt="call_icon" /></span>
              <span className=""><a href="https://wa.me/+919990955853" target="_blank" rel="noreferrer"><img src="images/whatsapp.png" className="marginLeft5" alt="whatsapp_icon" /></a></span>
              <span className=""><a href="mailto:info@gst-health.com" rel="noreferrer"><img src="images/email.png" className="marginLeft5" alt="email_icon" /></a></span>
              <span className=""><a href="https://www.facebook.com/gsthealth" rel="noreferrer" target="_blank"><img src="images/facebook_icon.png" className="marginLeft5" alt="facebook_icon" /></a></span>
              <span className=""><a href="https://www.instagram.com/gsthealth/" rel="noreferrer" target="_blank"><img src="images/instagram_icon.png" className="marginLeft5" alt="instagram_icon" /></a></span>
              
          </div>
        </div>
      </div>
      
      <div className="title-bar  hide-for-small-only" data-responsive-toggle="responsive-menu" data-hide-for="medium">
        <button className="menu-icon" type="button" data-toggle="responsive-menu"></button>
        {/* <div className="title-bar-title"><img src="images/home.png" width="20%" alt="home_button" /></div> */}
      </div>

      <div className="title-bar mobileMenu show-for-small-only" data-responsive-toggle="responsive-menu" data-hide-for="medium">
        <button className="menu-icon mobileMenuPM" type="button" data-toggle="responsive-menu"></button>
        {/* <div className="title-bar-title"><img src="images/home.png" width="20%" alt="home_button" /></div> */}
      </div>

      <div className="top-bar" id="responsive-menu">
        <div className="top-bar-left">
          <ul className="dropdown menu" data-dropdown-menu>
            
            <li className="has-submenu">
              <a href="/">HOME</a>
            </li>
            <li className="has-submenu">
              <NavLink to="/aboutUs">ABOUT US</NavLink>
            </li>
            <li className="has-submenu">
              <NavLink to="/StartBusiness">START A BUSINESS</NavLink>
            </li>
            <li className="has-submenu">
                <NavLink to="/">TAX &amp; ACCOUNTS</NavLink>
                <ul className="submenu menu vertical" data-submenu>
                  <li className="has-submenu">
                    <NavLink to="/GstService">GST SERVICES</NavLink>
                  </li>
                  <li className="has-submenu">
                    <NavLink to="/ITService">INCOME TAX</NavLink>
                  </li>
                  <li className="has-submenu">
                    <NavLink to="/BookAccountingService">BOOK KEEPING &amp; ACCOUNTING</NavLink>
                  </li>
                </ul>
            </li>
            <li className="has-submenu">
              <NavLink to="/">COMPLIANCE</NavLink>
              <ul className="submenu menu vertical" data-submenu>
                  <li className="has-submenu">
                    <NavLink to="">REGULAR COMPLIANCE</NavLink>
                    <ul className="submenu menu vertical" data-submenu>
                      <li><NavLink to="/regularCompliance">LIST OF REGULAR COMPLIANCE</NavLink></li>
                    </ul>
                    </li>
                  <li className="has-submenu">
                    <NavLink to="/WindingUp">WINDING UP</NavLink>
                  </li>
                  <li className="has-submenu">
                    <NavLink to="/additionalCompliance">ADDITIONAL COMPLIANCE</NavLink>
                    
                  </li>
                  <li className="has-submenu">
                    <NavLink to="/HRManage">HR MANAGEMENT</NavLink>
                  </li>
                </ul>
            </li>
            <li className="has-submenu">
                <NavLink to="/">REGISTRATIONS</NavLink>
                <ul className="submenu menu vertical" data-submenu>
                  <li><NavLink to="/DigitalSignature">DIGITAL SIGNATURE</NavLink></li>
                  <li><NavLink to="/MsmeRegistration">MSME REGISTRATION</NavLink></li>
                  <li><NavLink to="/FssaiRegistration">FSSAI REGISTRATION</NavLink></li>
                  <li><NavLink to="/EsciRegistration">ESIC REGISTRATION</NavLink></li>
                  <li><NavLink to="/tradeReg">TRADEMARK REGISTRATION</NavLink></li>
                  <li><NavLink to="/PTax">PROFESSIONAL TAX</NavLink></li>
                  <li><NavLink to="/ImportExportCode">IMPORT EXPORT CODE</NavLink></li>
                  <li><NavLink to="/PFAndForms">PF REGISTRATION & FORMS</NavLink></li>
                  {/* <li><NavLink to="/TradeLicence">TRADE LICENCE</NavLink></li> */}
                </ul>
            </li>
          <li className="has-submenu">
              <NavLink to="/contactUs">CONTACT US</NavLink>
          </li>
          </ul>
        </div>
      </div>
        </div>
    )
}
