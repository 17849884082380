import React from 'react'

const Page404 = () => {

    return (
        <div className="row">
        <h1>404 Page</h1>
        <img src="images/error_404.jpg" className="gst-logo-spin" alt="logo" width="100%"></img>
        Go to <a href="/"> Home </a> page.
        </div>
    )
}

export default Page404