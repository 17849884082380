import React from 'react'
import { NavLink } from 'react-router-dom';
import '../App.css';

export default function footer() {
    return (
        <>
        <div className="hide-for-small-only">
            <footer className="footerDesktop">
              <span className="leftFooter">
                    <ul className="menu horizontal">
                        <li><a href="/">Home</a></li>
                        <li><NavLink to="/aboutUs">About Us</NavLink></li>
                        <li><NavLink to="/StartBusiness">Start a Business</NavLink></li>
                        <li><NavLink to="/ITService">Income Tax</NavLink></li>
                        <li><NavLink to="/DigitalSignature">Digital Signature</NavLink></li>
                        <li><NavLink to="/GstService">GST Registration</NavLink></li>
                        <li><NavLink to="/contactUs">Contact Us</NavLink></li>
                    </ul>
              </span>
              <span className="rightFooter">
                  Copyrights © {new Date().getFullYear()} Gst-Health. All Rights Reserved.
              </span>
            </footer>
        </div>
        <div className="show-for-small-only">
            <footer>
              
              <span className="">
                  Copyrights © {new Date().getFullYear()} Gst-Health. All Rights Reserved.
              </span>
            </footer>
        </div>
        </>
        
    )
}
