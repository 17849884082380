import React from 'react';

function refreshPage() {
  window.location.reload(false);
}

const MsmeRegistration = () => {
    return (
        <div className="row">
            <div className="grid-container tabsContentContainer">
                <div className="grid-x">
                    <div className="cell large-12 small-12">
                        <p className="tabsContentHeading headingH1 borderOrange70 height70">MSME Registration</p>
                        <span className="headingBorderBottom spanGetTouchTxt"></span>
                        <p className="marginTop10">
                            Micro, Small and Medium Enterprises Development (MSMED) Act, 2006 is enacted by the Government of India and it is defined as “Enterprises involved in the manufacture or production, processing or preservation of goods as specified below: • A micro enterprise is an enterprise where investment in plant and machinery does not exceed Rs. 25 lakh • A small enterprise is an enterprise where the investment in plant and machinery is more than Rs. 25 lakh but does not exceed Rs. 5 crore • A medium enterprise is an enterprise where the investment in plant and machinery is more than Rs.5 crore but does not exceed Rs.10 crore.
                        </p>
                        <span className="headingH2 label primary marginTop10">Investment in plant and machinery</span>
                        <p className="marginTop10">
                            Investment in plant and machinery is the original cost excluding land and building and the items are specified by the Ministry of Small Scale Industries vide its notification No.S.O.1722(E) dated October 5, 2006
                            <br/> Enterprises involved in providing or rendering of services and whose investment in equipment (original cost excluding land and building and furniture, fittings and other items not directly related to the service provided or as may be notified under the MSMED Act, 2006 as specified below.
                            <br/> A micro enterprise is an enterprise where the investment in equipment should not exceed Rs. 10 lakh.
                            <br/> A small enterprise is an enterprise where the investment in equipment is more than Rs.10 lakh but does not exceed Rs. 2 crore.
                            <br/> A medium enterprise is an enterprise where the investment in equipment is more than Rs. 2 crore but does not exceed Rs. 5 crore.
                        </p>
                        <span className="headingH2 label primary marginTop10">Benefits</span>
                        <p className="marginTop10">
                            <span style={{fontWeight:"700"}}>1:</span> Bank loan become cheaper.<br/>
                            <span style={{fontWeight:"700"}}>2:</span> Tax Rebates.<br/>
                            <span style={{fontWeight:"700"}}>3:</span> Opportunities for government tenders and schemes.<br/>
                            <span style={{fontWeight:"700"}}>4:</span> Easy access to credit.<br/>
                        </p>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default MsmeRegistration
